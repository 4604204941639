import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthQuery, AuthService, UserProfileApiService } from '@ct/auth';
import { DialogService, InformationDialogComponent, SpinnerService, TabHeader } from '@ct/components';
import { DialogConfig } from '@ct/components/dialog/interfaces';
import { Destroyable, DestroyableFeature, Features, Group, UserProfile } from '@ct/core';
import { CreateGroupDialogComponent, GroupApiService, Mode, trackById } from '@ct/shared';
import { ManageGroupDialogComponent } from '@ct/shared/modules/group-shared/manage-group-dialog';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

enum GroupTab {
  Timeline = 'timeline',
  Members = 'members',
  Invitations = 'invitations',
  Requests = 'requests'
}

@Component({
  selector: 'ct-my-account-group-view',
  templateUrl: './my-account-group-view.component.html',
  styleUrls: ['./my-account-group-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class MyAccountGroupViewComponent extends Destroyable {
  public tabs: TabHeader[] = [
    { name: GroupTab.Timeline, labelKey: 'Timeline', selected: true },
    { name: GroupTab.Members, labelKey: 'Members', shouldShow: () => this.isMember },
    { name: GroupTab.Invitations, labelKey: 'Invitations', shouldShow: () => this.isAdmin },
    { name: GroupTab.Requests, labelKey: 'Requests', shouldShow: () => this.isAdmin }
  ];

  public group: Group;

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public trackByFn = trackById;

  get isAdmin(): boolean {
    return (
      Boolean(this.group) &&
      Boolean(this.authQuery.profile) &&
      Boolean(this.group.adminIds?.includes(this.authQuery.profile?.userId as string))
    );
  }

  get isMember(): boolean {
    const profile = this.authQuery.profile as UserProfile;
    return (
      Boolean(this.group) &&
      Boolean(profile) &&
      Boolean(
        profile.groupIds?.includes(String(this.group.id)) ||
          profile.pendingInvitationGroupIds?.includes(String(this.group.id))
      )
    );
  }

  get canLeave(): boolean {
    const profile = this.authQuery.profile as UserProfile;
    return (
      !this.isAdmin &&
      Boolean(this.group) &&
      Boolean(profile) &&
      Boolean(profile.groupIds?.includes(String(this.group.id)))
    );
  }

  get canJoin(): boolean {
    const profile = this.authQuery.profile as UserProfile;
    return Boolean(profile) && !this.isAdmin && !this.canLeave && !this.isInvited && !this.requestedToJoin;
  }

  get isInvited(): boolean {
    const profile = this.authQuery.profile as UserProfile;
    return (
      Boolean(this.group) &&
      Boolean(profile) &&
      Boolean(profile.pendingInvitationGroupIds?.includes(String(this.group.id)))
    );
  }

  get requestedToJoin(): boolean {
    const profile = this.authQuery.profile as UserProfile;
    return (
      Boolean(this.group) && Boolean(profile) && Boolean(profile.pendingReviewGroupIds?.includes(String(this.group.id)))
    );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private dialogService: DialogService,
    private groupApiService: GroupApiService,
    private userProfileApiService: UserProfileApiService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        })
      )
      .subscribe((route: ActivatedRoute) => {
        const path = route.snapshot.url[0]?.path;
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          selected: tab.name === path
        }));
      });

    let actRoute: ActivatedRoute = this.route;

    while (actRoute.firstChild) {
      actRoute = actRoute.firstChild;
    }

    actRoute.data.pipe(takeUntil(this.destroyed$)).subscribe(({ group }) => {
      this.group = group;
    });
  }

  onFilterChanged(tab: TabHeader) {
    this.router.navigate(['/my-account/groups', this.group.id as string, tab.name]);
  }

  acceptInvitation() {
    this.groupApiService.acceptInvitation(this.group.id as string).subscribe(() => location?.reload());
  }

  joinGroup() {
    this.groupApiService
      .joinGroup(this.group.id as string)
      .pipe(
        switchMap(() => this.loggedInUser$.pipe(take(1))),
        switchMap((userProfile) => this.userProfileApiService.getById(userProfile?.id as string)),
        switchMap((userProfile) => this.authService.updateUserProfile(userProfile))
      )
      .subscribe();
  }

  leaveGroup() {
    const currentUser = this.authQuery.profile as UserProfile;
    if (this.group.adminIds?.includes(currentUser?.userId) && this.group.adminIds?.length === 1) {
      this.dialogService.open(InformationDialogComponent, {
        data: {
          title: 'Warning',
          message: 'Group should have at least one admin',
          canConfirm: false
        },
        width: '450px'
      } as MatDialogConfig<DialogConfig>);
      return;
    }
    this.groupApiService
      .cancelRequest(this.group.id as string)
      .pipe(
        switchMap(() => this.loggedInUser$.pipe(take(1))),
        switchMap((userProfile) => this.userProfileApiService.getById(userProfile?.id as string)),
        switchMap((userProfile) => this.authService.updateUserProfile(userProfile))
      )
      .subscribe(() => this.router.navigate(['/my-account/groups/public']));
  }

  editGroup() {
    this.dialogService
      .open(CreateGroupDialogComponent, {
        data: {
          mode: Mode.Edit,
          group: this.group
        }
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((group) => {
        this.group = group as Group;
        this.cdr.detectChanges();
      });
  }

  manageAdmins() {
    this.spinnerService.show({ instant: true });
    this.groupApiService
      .findAllGroupMembers(this.group.id as string, { range: { limit: 0, offset: 0 } })
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe((users) => {
        this.dialogService
          .open(ManageGroupDialogComponent, {
            data: {
              group: this.group,
              users,
              currentUserId: this.authQuery.profile?.userId
            }
          })
          .afterClosed()
          .pipe(
            takeUntil(this.destroyed$),
            switchMap(() => {
              return this.groupApiService.getById(this.group.id as string);
            })
          )
          .subscribe((group) => (this.group = group));
      });
  }
}
