import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '@ct/components';
import { DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { JournalEntryApiService, trackById, TripJournalEntry } from '@ct/shared';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { MY_ACCOUNT_TRIPS_FEATURE_LIMIT } from '../../../../constants';

interface QueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-select-journal-dialog',
  templateUrl: './select-journal-dialog.component.html',
  styleUrls: ['./select-journal-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectJournalDialogComponent {
  static dialogConfig: MatDialogConfig = {
    minWidth: '700px'
  };
  public readonly destroyed$: Observable<void>;
  public readonly limit = MY_ACCOUNT_TRIPS_FEATURE_LIMIT;
  public loading = false;
  public showLoadButton = true;
  public journalEntries: TripJournalEntry[] = [];

  public queryParams: QueryParams = {
    offset: DEFAULT_OFFSET
  };

  public readonly trackByFn = trackById;

  constructor(
    private dialogRef: MatDialogRef<SelectJournalDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private journalEntryApiService: JournalEntryApiService,
    private spinnerService: SpinnerService
  ) {
    this.loading = true;
    this.loadJournals(this.queryParams).subscribe((journalEntries) => {
      this.journalEntries.push(...journalEntries);
      if (journalEntries.length === 0 || journalEntries.length < this.limit) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  onSelect(event: MouseEvent, journalEntry: TripJournalEntry) {
    event.stopPropagation();
    event.preventDefault();

    this.dialogRef.close(journalEntry);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadJournals(this.queryParams).subscribe((journalEntries) => {
      this.journalEntries.push(...journalEntries);
      if (journalEntries.length === 0 || journalEntries.length < this.limit) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  loadJournals({ offset }: QueryParams) {
    this.spinnerService.show();
    this.loading = true;
    return this.journalEntryApiService.getAll({ range: { limit: this.limit, offset } }).pipe(
      finalize(() => {
        this.loading = false;
        this.spinnerService.hide();
      })
    );
  }
}
