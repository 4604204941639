import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SelectStoryDialogComponent } from '@ct/app-content/my-account/modules/my-account-groups/components';
import { DialogService, IconType } from '@ct/components';
import { DestroyableFeature, Features, FormStateDispatcher, GroupTimelineType } from '@ct/core';
import { Observable } from 'rxjs';

import { SelectJournalDialogComponent } from '../select-journal-dialog';
import { SelectTripDialogComponent } from '../select-trip-dialog';

interface AddOption {
  icon: IconType;
  titleKey: string;
  type: GroupTimelineType;
}

@Component({
  selector: 'ct-select-group-entity-dialog',
  templateUrl: './select-group-entity-dialog.component.html',
  styleUrls: ['./select-group-entity-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectGroupEntityDialogComponent {
  public static dialogConfig: MatDialogConfig = {
    width: '30%',
    minWidth: '380px'
  };

  public readonly destroyed$: Observable<void>;
  public readonly addOptions: AddOption[] = [
    {
      icon: 'cabin',
      titleKey: 'Trip',
      type: GroupTimelineType.Trip
    },
    {
      icon: 'book',
      titleKey: 'Memory',
      type: GroupTimelineType.Journal
    },
    {
      icon: 'book',
      titleKey: 'Story',
      type: GroupTimelineType.BlogPost
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<SelectGroupEntityDialogComponent>,
    private dialogService: DialogService
  ) {}

  onOptionSelect(option: AddOption) {
    switch (option.type) {
      case GroupTimelineType.Trip:
        return this.onSelectTrip();
      case GroupTimelineType.BlogPost:
        return this.onSelectStory();
      case GroupTimelineType.Journal:
        return this.onSelectJournal();
    }
  }

  onSelectTrip() {
    this.dialogService
      .open(SelectTripDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Trip } : null)
      );
  }

  onSelectStory() {
    this.dialogService
      .open(SelectStoryDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.BlogPost } : null)
      );
  }

  onSelectJournal() {
    this.dialogService
      .open(SelectJournalDialogComponent)
      .afterClosed()
      .subscribe((result) =>
        this.dialogRef.close(result ? { entity: result, entityType: GroupTimelineType.Journal } : null)
      );
  }
}
