<ct-autocomplete
  [label]="label || ('MY_ACCOUNT.FRIENDS_FEATURE.FIND_USER' | translate)"
  [placeholder]="placeholder || ('MY_ACCOUNT.FRIENDS_FEATURE.FIND_USER_PLACEHOLDER' | translate)"
  [optionsFn]="optionsFn"
  [maxlength]="maxlength"
  [formControl]="autocompleteControl"
  [focusAfterSubmit]="true"
  [hint]="false"
  [attr.compact]="true"
  [customOptionsTemplate]="optionRef"
  (changed)="changed($event)"
>
  <ng-template #optionRef let-option="option">
    <div class="user">
      <ct-user-avatar
        class="user__avatar"
        [src]="getCroppedThumbPublicUrl(option?.photo?.publicUrl)"
        width="32px"
        height="32px"
      ></ct-user-avatar>
      <div>
        <p class="user__name">{{ option.firstName }} {{ option.lastName }}</p>
        <p class="user__name user__name--light">{{ option.username }}</p>
      </div>
    </div>
  </ng-template>
</ct-autocomplete>
