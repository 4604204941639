import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService, HeaderType, MediaArchiveLinks, RequestRange, UploadedImage } from '@ct/core';
import { environment } from '@ct/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortOrder } from '../enums';
import { ImageMetadata } from '../interfaces';

const endpoint = environment.fileUploadApiBaseUrl;

export interface ImageUploadFilterParams {
  range: RequestRange | undefined;
  authorId?: string | undefined;
  sortOrder?: SortOrder | undefined;
  tripId?: string | undefined;
  campsiteId?: string | undefined;
  isPrivate?: boolean | undefined;
  isMarketplaceItem?: boolean | undefined;
}

@Injectable({ providedIn: 'root' })
export class ImageUploadApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    sortOrder = SortOrder.Desc,
    authorId,
    tripId,
    campsiteId,
    isPrivate,
    isMarketplaceItem
  }: ImageUploadFilterParams): Observable<UploadedImage[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (tripId !== undefined && tripId !== null) {
      params = params.append('tripId', tripId);
    }
    if (campsiteId !== undefined && campsiteId !== null && campsiteId !== 'all') {
      params = params.append('campsiteId', campsiteId);
    }
    if (isPrivate !== undefined && isPrivate !== null) {
      params = params.append('isPrivate', isPrivate);
    }
    if (isMarketplaceItem !== undefined && isMarketplaceItem !== null) {
      params = params.append('isMarketplaceItem', isMarketplaceItem);
    }
    return this.get(`images`, params, {
      headers
    });
  }

  getById(id: string): Observable<UploadedImage | undefined> {
    return this.get(`images/${id}`, { withCredentials: true });
  }

  getByIds(ids: string[]): Observable<UploadedImage[]> {
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    return this.get(`images/byIdList`, params, { withCredentials: true });
  }

  startArchiving(): Observable<void> {
    return this.post(`images/download-all`, null);
  }

  getArchives(): Observable<MediaArchiveLinks | undefined> {
    return this.get(`images/download-all`);
  }

  uploadWithProgress(file: File) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    const formData = new FormData();
    formData.append('file', file);
    // use http client directly as baseHttpService does not support reportProgress property
    return this.httpClient.post(this.URL + `/images`, formData, { headers, reportProgress: true, observe: 'events' });
  }

  upload(file: File) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    const formData = new FormData();
    formData.append('file', file);
    return this.post(`images`, formData, {}, { headers });
  }

  update(id: string, photo: Partial<UploadedImage>) {
    return this.patch(`images/${id}`, { ...photo }, {}, {});
  }

  getMetadata(id: string): Observable<ImageMetadata | undefined> {
    return this.get(`images/${id}/metadata`, { withCredentials: true });
  }

  updateMetadata(id: string, metadata: Partial<ImageMetadata>) {
    return this.patch(`images/${id}/metadata`, { ...metadata }, {}, {});
  }

  remove(id: string) {
    return this.delete(`images/${id}`);
  }

  getUploadLink(entity: File) {

    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.httpClient
      .post<{ data: UploadedImage & { uploadLink: string } }>(this.URL + `/images/upload-link`, { ...entity, originalname: entity.name }, { headers })
      .pipe(map(({ data }) => data));
  }

  useUploadLink(file: File, url: string) {
    const headers = {
      [HeaderType.ContentType]: 'application/octet-stream'
    };
    return this.httpClient.put(url, file, { headers });
  }
}
