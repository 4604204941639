<ct-dialog-content
  titleKey="MY_ACCOUNT.MY_POSTS_FEATURE.STORY"
  headerIcon="add-circle"
  [buttons]="buttons"
  [alignButtons]="'end'"
>
  <section class="post">
    <form class="form" [formGroup]="form">
      <div class="row">
        <ct-input
          class="col-12"
          [label]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE' | translate"
          [placeholder]="'MY_ACCOUNT.MY_POSTS_FEATURE.TITLE_PLACEHOLDER' | translate"
          formControlName="name"
        ></ct-input>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-md-3">
          <h2 class="item-title">{{ 'Channel Avatar' | translate }}</h2>
          <ct-new-item-placeholder
            class="placeholder"
            [img]="form.controls.avatarImage?.value?.publicUrl"
            [textKey]="'MY_ACCOUNT.MY_POSTS_FEATURE.ADD_NEW_PHOTO'"
            [replaceKey]="
              form.controls.avatarImage?.value?.publicUrl ? 'MY_ACCOUNT.MY_POSTS_FEATURE.REPLACE_PHOTO' : ''
            "
            (placeholderClicked)="onSelectFeaturedPhotoPlaceholder('avatarImage')"
            (replaced)="onReplaceFeaturedPhoto('avatarImage')"
            (removed)="onRemoveFeaturedPhoto('avatarImage')"
          ></ct-new-item-placeholder>
        </div>
        <div class="col-12 col-md-9">
          <h2 class="item-title">{{ 'Channel Banner' | translate }}</h2>
          <ct-new-item-placeholder
            class="placeholder"
            [img]="form.controls.bannerImage?.value?.publicUrl"
            [textKey]="'MY_ACCOUNT.MY_POSTS_FEATURE.ADD_NEW_PHOTO'"
            [replaceKey]="
              form.controls.bannerImage?.value?.publicUrl ? 'MY_ACCOUNT.MY_POSTS_FEATURE.REPLACE_PHOTO' : ''
            "
            (placeholderClicked)="onSelectFeaturedPhotoPlaceholder('bannerImage')"
            (replaced)="onReplaceFeaturedPhoto('bannerImage')"
            (removed)="onRemoveFeaturedPhoto('bannerImage')"
          ></ct-new-item-placeholder>
        </div>
        <div class="col-12">
          <h3 class="item-title">{{ 'Channel Banner Size' | translate }}</h3>
          <ct-select
            [options]="bannerSizes"
            [label]="'Channel Banner Image Size' | translate"
            [placeholder]="'Channel Banner Image Size' | translate"
            formControlName="bannerImageSize"
          ></ct-select>
        </div>
      </div>
      <div class="row">
        <ct-textarea class="col-12" formControlName="description"></ct-textarea>
      </div>
      <div class="row">
        <ct-input
          class="col-12"
          [label]="'URL handle' | translate"
          [placeholder]="'URL handle' | translate"
          formControlName="handle"
        ></ct-input>
      </div>
      <div class="row">
        <ct-input
          class="col-12"
          [label]="'Google Calendar Sharing URL' | translate"
          [placeholder]="'Paste public URL for Google Calendar here' | translate"
          formControlName="googleCalendarPublicUrl"
        ></ct-input>
      </div>
    </form>
  </section>
</ct-dialog-content>
