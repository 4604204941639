import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DeletionDialogComponent, DialogService } from '@ct/components';
import { DestroyableFeature, Features, Group, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { getCroppedThumbPublicUrl, GroupApiService, trackById } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize, switchMap, take } from 'rxjs/operators';

import { MY_ACCOUNT_GROUPS_FEATURE_LIMIT } from './../../../../constants/';

interface SimpleQueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-my-account-group-members',
  templateUrl: './my-account-group-members.component.html',
  styleUrls: ['./my-account-group-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature()])
export class MyAccountGroupMembersComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_PHOTOS'
  };
  public readonly limit = MY_ACCOUNT_GROUPS_FEATURE_LIMIT;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  public members: UserProfile[] = [];
  public group: Group;
  public loading = false;
  public showLoadButton = true;
  public multiselect = false;

  public queryParams: SimpleQueryParams = {
    offset: DEFAULT_OFFSET
  };

  get isAdmin() {
    return (
      this.group && this.authQuery.profile && this.group.adminIds?.includes(this.authQuery.profile.userId as string)
    );
  }

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public trackByFn = trackById;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountGroupApiService: GroupApiService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ members, group }) => {
      this.members = members;
      this.group = group;
    });
    this.route.queryParams.pipe(take(1)).subscribe(({ offset }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset
      };
      this.showLoadButton = !(this.members.length === 0 || this.members.length < this.limit);
    });
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadMembers(this.queryParams).subscribe((members) => this.refreshMembers(members));
  }

  loadMembers({ offset }: SimpleQueryParams) {
    this.loading = true;
    return this.myAccountGroupApiService
      .findAllGroupMembers(this.group.id as string, { range: { limit: this.limit, offset } })
      .pipe(finalize(() => (this.loading = false)));
  }

  refreshMembers(members: UserProfile[]) {
    if (this.queryParams.offset === DEFAULT_OFFSET) {
      this.members = [...members];
    } else {
      this.members = [...this.members, ...members];
    }
    this.showLoadButton = !(members.length === 0 || members.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  removeMember({ username, userId }: UserProfile) {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.CONFIRM_REMOVE.TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.CONFIRM_REMOVE.MESSAGE', {
            username
          })
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.myAccountGroupApiService.removeUserFromGroup(this.group.id as string, userId))
      )
      .subscribe(() => {
        this.members = this.members.filter((member) => member.userId !== userId);
      });
  }
}
