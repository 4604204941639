import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { UserProfile } from '@ct/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AiChatApiService,
  ChatButton,
  ChatItem,
  ChatMessage,
  ChatMessageSender,
  ChatMessageType,
  ChatService
} from '../../services';

@Component({
  selector: 'ct-my-account-chat',
  templateUrl: './my-account-chat.component.html',
  styleUrls: ['./my-account-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountChatComponent implements AfterViewChecked {
  @ViewChild('chatContainer') private chatContainer!: ElementRef;
  protected readonly messageControl = new FormControl<string>('');
  protected readonly ChatMessageSender = ChatMessageSender;
  protected readonly loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;

  constructor(
    public chatService: ChatService,
    private readonly aiChatService: AiChatApiService,
    private readonly authQuery: AuthQuery,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngAfterViewChecked() {
    this.scrollToLatestMessage();
  }

  scrollToLatestMessage() {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) {
      // silently catch if the view isn't fully rendered yet
    }
  }

  async sendMessage() {
    const message = this.messageControl.value?.trim();
    if (!message) return;

    // Add 'me' message
    this.addMessage(ChatMessageSender.User, message);

    // Clear input
    this.messageControl.patchValue('', { emitEvent: false });

    if (message.toLowerCase() === 'action!') {
      setTimeout(() => {
        this.startEmulationFlow();
      }, 5000);
    } else {
      const destroyed$ = new Subject();
      this.aiChatService.messageStream
        .pipe(takeUntil(destroyed$))
        .subscribe(({ id, message }) => this.updateMessage(id, ChatMessageSender.Assistant, message));
      await this.aiChatService.createChatCompletion({
        model: 'deepseek-ai/DeepSeek-R1-Distill-Qwen-1.5B',
        messages: this.chatService.messages.map(({ sender, text }) => ({ role: sender, content: text as string })),
        stream: true,
        temperature: 0.7
      });

      setTimeout(() => {
        destroyed$.next();
      }, 500);
    }
  }

  closeChat() {
    // Toggle the chat closed
    this.chatService.isChatOpen = false;
  }

  onButtonClick(message: ChatMessage, btn: ChatButton) {
    // 1. Hide the buttons on the original message
    message.showButtons = false;

    // 2. Send user’s choice as a new user message
    this.addMessage(ChatMessageSender.User, btn.label);

    // 3. Handle logic. For instance:
    if (btn.value === true) {
      this.addMessage(ChatMessageSender.Assistant, 'Great, navigating now...');
      setTimeout(() => {
        this.router.navigate(['/my-account', 'trips']);

        setTimeout(() => {
          this.locationSelection();
        }, 5000);
      }, 500);
    } else {
      // The user chose "Stay here"
      this.addMessage(ChatMessageSender.Assistant, 'Alright, staying on this page.');

      setTimeout(() => {
        this.locationSelection();
      }, 5000);
    }
  }

  onAddItems(message: ChatMessage) {
    this.addMessage(ChatMessageSender.User, `Add all items to my trip`);

    setTimeout(() => {
      this.addMessage(ChatMessageSender.Assistant, `Done! All items were added to your trip.`);
    }, 5000);
  }

  startEmulationFlow() {
    this.addMessage(ChatMessageSender.User, `Let's create new trip`);

    setTimeout(() => {
      this.addMessage(
        ChatMessageSender.Assistant,
        'Would you like to navigate to trips list?',
        ChatMessageType.Buttons,
        [
          { label: 'Yes', value: true },
          { label: 'Stay here', value: false }
        ]
      );
    }, 5000);
  }

  private locationSelection() {
    console.log('locationSelection');
    this.addMessage(ChatMessageSender.Assistant, 'Where would you like to go?');

    setTimeout(() => {
      this.addMessage(ChatMessageSender.User, 'Somewhere not far away');

      setTimeout(() => {
        this.addMessage(ChatMessageSender.Assistant, 'Have you been to Ozark previously?');

        setTimeout(() => {
          this.addMessage(ChatMessageSender.User, 'Nope, tell me more about it');

          setTimeout(() => {
            this.addMessage(
              ChatMessageSender.Assistant,
              'Enjoy scenic drives through rolling hills and dense forests, with plenty of fully equipped RV-friendly campgrounds offering lakefront views. Many sites also provide easy access to hiking trails, fishing spots, and lively local towns.'
            );

            setTimeout(() => {
              this.addMessage(ChatMessageSender.User, 'Okay, sounds good. What should I pack with me?');
              setTimeout(() => {
                this.listGeneration();
              }, 5000);
            }, 5000);
          }, 5000);
        }, 5000);
      }, 5000);
    }, 5000);
  }

  private listGeneration() {
    const items: ChatItem[] = [
      {
        name: 'Weather Layers',
        description: 'Adapt to changing temperatures.'
      },
      {
        name: 'Sturdy Boots',
        description: 'Provide traction on rugged trails.'
      },
      {
        name: 'Bug Spray',
        description: 'Keep mosquitoes and ticks at bay.'
      },
      {
        name: 'Water Bottle',
        description: 'Stay hydrated throughout the day.'
      },
      {
        name: 'Headlamp',
        description: 'Helpful for nighttime visibility.'
      },
      {
        name: 'Camp Stove',
        description: 'Convenient for outdoor cooking.'
      },
      {
        name: 'Ice Cooler',
        description: 'Preserve perishables and drinks.'
      },
      {
        name: 'Toiletry Kit',
        description: 'Essentials for personal hygiene.'
      },
      {
        name: 'Folding Chairs',
        description: 'Relax in comfort outside.'
      },
      {
        name: 'Trail Maps',
        description: 'Navigate remote areas effectively.'
      },
      {
        name: 'First Aid',
        description: 'Handle minor injuries quickly.'
      },
      {
        name: 'Firewood Supply',
        description: 'Fuel evening campfires (if allowed).'
      },
      {
        name: 'Multi-Tool',
        description: 'Useful for quick repairs or tasks.'
      }
    ];

    this.addMessage(
      ChatMessageSender.Assistant,
      `Here are some good to have items:`,
      ChatMessageType.Items,
      undefined,
      items
    );
  }

  updateMessage(
    messageId: string,
    sender: ChatMessageSender,
    text: string,
    type: ChatMessageType = ChatMessageType.Text
  ) {
    this.chatService.updateMessage(messageId, sender, text, type);
    this.cdr.detectChanges();
    setTimeout(() => {
      this.scrollToLatestMessage();
    }, 500);
  }

  private addMessage(
    sender: ChatMessageSender,
    text: string,
    type: ChatMessageType = ChatMessageType.Text,
    buttons?: ChatButton[],
    items?: ChatItem[]
  ) {
    this.chatService.addMessage(sender, text, type, buttons, items);
    this.cdr.detectChanges();
    setTimeout(() => {
      this.scrollToLatestMessage();
    }, 500);
  }
}
