import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  CommentApiService,
  DestroyableFeature,
  EntityComment,
  EntityThread,
  Features,
  ThreadApiService,
  trackById,
  UserProfile
} from '@ct/core';
import { EntityType } from '@ct/shared/enums/share-entity-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, take, takeUntil } from 'rxjs/operators';

import { DeletionDialogComponent, DialogService } from '../../dialog';
import { NewCommentService } from '../new-comment.service';

@Component({
  selector: 'ct-entity-comments',
  templateUrl: './entity-comments.component.html',
  styleUrls: ['./entity-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class EntityCommentsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  messageMapping: any = {
    '=0': 'MY_ACCOUNT.MY_POSTS_FEATURE.NO_COMMENTS',
    '=1': 'MY_ACCOUNT.MY_POSTS_FEATURE.ONE_COMMENT',
    other: 'MY_ACCOUNT.MY_POSTS_FEATURE.COUNT_COMMENTS'
  };
  public readonly destroyed$: Observable<void>;
  public trackByFn = trackById;

  @ViewChild('vc', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('containerWrapper') containerWrapper: ElementRef;

  @Input() public threads: EntityThread[] = [];
  @Input() user: UserProfile | null;
  @Input() isAdminView: boolean;
  @Input() entityId?: string;
  @Input() entityType?: EntityType;
  @Input() disableComments?: boolean;
  @Input() hideCount?: boolean = true;
  @Input() fetchOnInit = true;

  protected readonly isLoading$ = new BehaviorSubject<boolean>(false);

  get itemsCount() {
    if (!this.threads?.length) {
      return 0;
    }
    return this.threads.reduce((acc, thread) => acc + (thread.comments?.length || 0), 0) + this.threads.length;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private newCommentService: NewCommentService,
    private threadApiService: ThreadApiService,
    private commentApiService: CommentApiService
  ) {}

  ngOnInit() {
    if (this.fetchOnInit) {
      this.initThreads();
    }
  }

  ngOnChanges({ user, post }: SimpleChanges) {
    if (user?.currentValue && !user.firstChange) {
      this.initNewComment();
    }

    if (post?.currentValue !== post?.previousValue) {
      this.initThreads();
    }
  }

  ngAfterViewInit() {
    this.initNewComment();

    this.newCommentService.containerCleared.subscribe(() => this.initNewComment());
  }

  initThreads() {
    this.threadApiService.getAll(this.entityId as string).subscribe((threads) => {
      this.threads = threads;
      this.changeDetectorRef.detectChanges();
    });
  }

  initNewComment() {
    if (!this.isLoggedIn || this.disableComments || this.isAdminView) {
      return;
    }
    setTimeout(() => {
      const instance = this.newCommentService.create(this.container);
      instance.user = this.user;
      instance.cancelable = false;
      instance.submitted.pipe(takeUntil(this.destroyed$)).subscribe((value: string) => {
        instance.disableButtons = true;
        instance.isLoading = true;
        this.publishThread(value);
      });
      this.changeDetectorRef.markForCheck();
    });
  }

  onEditThread(thread: EntityThread) {
    const instance = this.newCommentService.create(this.container);
    instance.user = this.user;
    instance.submitButtonKey = 'COMMON.UPDATE';
    instance.form.controls.body.patchValue(thread.body);
    this.containerWrapper.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    instance.submitted.pipe(takeUntil(this.destroyed$)).subscribe((value: string) => {
      instance.disableButtons = true;
      instance.isLoading = true;
      this.editThread({ threadId: thread?.id, value });
    });
    instance.cancelled.pipe(takeUntil(this.destroyed$)).subscribe(() => this.newCommentService.clear());
  }

  onPublishComment({ threadId, value }: { threadId: string; value: string }) {
    this.publishComment({ threadId, value });
  }

  onDeleteComment({ threadId, commentId }: { threadId: string; commentId: string }) {
    this.showDeleteWarning().subscribe(() => this.deleteComment({ threadId, commentId }));
  }

  onEditComment({ threadId, commentId, value }: { threadId: string; commentId: string; value: string }) {
    this.editComment({ threadId, commentId, value });
  }

  onDeleteThread(thread: EntityThread) {
    this.showDeleteWarning().subscribe(() => this.deleteThread(thread.id));
  }

  private publishThread(value: string) {
    this.isLoading$.next(true);
    this.threadApiService
      .create(this.entityId as string, value, this.entityType)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((thread) => {
        this.threads = [thread].concat(this.threads);
        this.newCommentService.clear();
        this.changeDetectorRef.detectChanges();
      });
  }

  private publishComment({ threadId, value }: { threadId: string; value: string }) {
    this.isLoading$.next(true);
    this.commentApiService
      .create(this.entityId as string, threadId, value, this.entityType)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((comment: EntityComment) => {
        const threadIndex = this.threads.findIndex((thread) => thread.id === threadId);
        this.threads[threadIndex] = {
          ...this.threads[threadIndex],
          comments: [comment].concat(this.threads[threadIndex].comments || [])
        };
        this.threads = [...this.threads];
        this.changeDetectorRef.detectChanges();
      });
  }

  private editComment({ threadId, commentId, value }: { threadId: string; commentId: string; value: string }) {
    this.isLoading$.next(true);
    this.commentApiService
      .update(threadId, commentId, value)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((comment) => {
        const threadIndex = this.threads.findIndex((_thread) => _thread.id === threadId);
        const commentIndex = this.threads[threadIndex].comments.findIndex((_comment) => _comment.id === commentId);
        this.threads[threadIndex].comments[commentIndex] = { ...comment };
        this.threads[threadIndex] = {
          ...this.threads[threadIndex],
          comments: [...this.threads[threadIndex].comments]
        };
        this.threads = [...this.threads];
        this.changeDetectorRef.detectChanges();
      });
  }

  private editThread({ threadId, value }: { threadId: string; value: string }) {
    this.isLoading$.next(true);
    this.threadApiService
      .update(threadId, value)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe((thread) => {
        const threadIndex = this.threads.findIndex((_thread) => _thread.id === threadId);
        this.threads[threadIndex] = { ...thread };
        this.threads = [...this.threads];
        this.newCommentService.clear();
        this.changeDetectorRef.detectChanges();
      });
  }

  private deleteComment({ threadId, commentId }: { threadId: string; commentId: string }) {
    this.isLoading$.next(true);
    this.commentApiService
      .remove(threadId, commentId)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe(() => {
        const threadIndex = this.threads.findIndex((_thread) => _thread.id === threadId);
        this.threads[threadIndex] = {
          ...this.threads[threadIndex],
          comments: this.threads[threadIndex].comments.filter((comment) => comment.id !== commentId)
        };
        this.threads = [...this.threads];
        this.changeDetectorRef.detectChanges();
      });
  }

  private deleteThread(threadId: string) {
    this.isLoading$.next(true);
    this.threadApiService
      .remove(threadId)
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe(() => {
        this.threads = this.threads.filter((thread) => thread.id !== threadId);
        this.changeDetectorRef.detectChanges();
      });
  }

  private showDeleteWarning() {
    return this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.DELETE_COMMENT_TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.MY_POSTS_FEATURE.DELETE_COMMENT_MESSAGE')
        }
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean));
  }

  ngOnDestroy() {
    this.newCommentService.destroy();
  }
}
