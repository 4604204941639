import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@ct/core';
import { environment } from '@ct/environment';
import { Subject } from 'rxjs';

import { AiChatRequest, AiChatResponse } from '../interfaces';

const endpoint = environment.aiChatApiBaseUrl;

@Injectable({ providedIn: 'root' })
export class AiChatApiService extends BaseHttpService {
  protected messageStream$ = new Subject<{ id: string; message: string }>();
  public messageStream = this.messageStream$.asObservable();

  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint ?? '');
  }

  async createChatCompletion(request: AiChatRequest): Promise<void> {
    const controller = new AbortController();
    const signal = controller.signal;
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      ...request,
      stream: true
    };
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
      signal
    };
    try {
      const response: Response = await fetch(this.URL, options);
      const reader: ReadableStreamDefaultReader<string> | undefined = response.body
        ?.pipeThrough(new TextDecoderStream())
        .getReader();
      if (!reader) {
        throw new Error('Error occurred while creating stream reader');
      }
      let message = '';
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        const lines: string[] = value.split('\n');
        try {
          const parsedLines: AiChatResponse[] = this.getParsedStreamedCompletionResponse(lines);
          for (const line of parsedLines) {
            const { choices, id } = line;
            const { delta } = choices[0];
            if (delta?.content) {
              message += delta.content;
              this.messageStream$.next({ id, message });
            }
          }
        } catch (error) {
          console.error({ error });
        }
      }
    } catch (error) {
      if (signal.aborted) {
        console.error('Request aborted.');
      } else {
        this.handleError(error);
      }
    } finally {
      controller.abort();
      console.log('Stream stoped');
    }
  }
  /**
   * Parses the streamed response from an OpenAI API chat completion request into an array of completion objects.
   *
   * u/param {string[]} lines - An array of strings representing lines of the streamed response.
   * u/returns {ICompletion[]} - An array of completion objects parsed from the streamed response.
   */
  private getParsedStreamedCompletionResponse(lines: string[]): AiChatResponse[] {
    return lines
      .map((line) => line.replace(/^data: /, '').trim())
      .filter((line) => line !== '' && line !== '[DONE]')
      .map((line) => JSON.parse(line));
  }
  /**
   * Handles any errors that occur during the chat completion request process.
   *
   * u/param {any} error - The error object to handle.
   */
  private handleError(error: any) {
    console.error('Error:', error);
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new Error('Request was aborted');
    } else if (error instanceof TypeError && error.message === 'Failed to fetch') {
      throw new Error('Network error occurred');
    } else if (error instanceof TypeError && error.message === 'Failed to decode') {
      throw new Error('Error decoding response from server');
    } else if (
      error instanceof TypeError &&
      error.message === 'JSON.parse: unexpected end of data at line 1 column 1 of the JSON data'
    ) {
      throw new Error('Invalid JSON response from server');
    } else if (
      error instanceof TypeError &&
      error.message === 'response.body?.pipeThrough(...).getReader is not a function'
    ) {
      throw new Error('Invalid response from server');
    } else {
      throw new Error('Unknown error occurred');
    }
  }
}
