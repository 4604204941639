<section class="threads">
  <p *ngIf="!disableComments && !hideCount" class="items-count">
    {{ itemsCount | i18nPlural : messageMapping | translate : { count: itemsCount } }}
  </p>
  <div *ngIf="disableComments && isLoggedIn" class="bordered-box">
    {{ 'MY_ACCOUNT.MY_POSTS_FEATURE.NOT_ALLOWED_TO_COMMENT' | translate }}
  </div>
  <ng-container *ngIf="!disableComments">
    <ct-entity-thread
      *ngFor="let thread of threads; trackBy: trackByFn"
      class="thread"
      [thread]="thread"
      [user]="user"
      [isLoading]="(isLoading$ | async) === true"
      (publishedComment)="onPublishComment($event)"
      (editedThread)="onEditThread($event)"
      (deletedThread)="onDeleteThread($event)"
      (editedComment)="onEditComment($event)"
      (deletedComment)="onDeleteComment($event)"
    >
      <ct-entity-comment
        *ngFor="let comment of thread?.comments; trackBy: trackByFn"
        [comment]="comment"
        [user]="user"
        (edited)="onEditComment($event)"
        (deleted)="onDeleteComment($event)"
      ></ct-entity-comment>
    </ct-entity-thread>
  </ng-container>
  <div *ngIf="!isLoggedIn" class="bordered-box">
    {{ 'MY_ACCOUNT.MY_POSTS_FEATURE.NOT_AUTHORIZED_TO_COMMENT' | translate }}
  </div>
  <div #containerWrapper>
    <div class="loading-indicator-wrapper">
      <ct-icon *ngIf="isLoading$ | async" icon="loading" class="loading-indicator"></ct-icon>
    </div>
    <ng-container #vc></ng-container>
  </div>
</section>
