import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ChannelVideoUploadApiService, GroupTimeline } from '@ct/core';
import { GroupApiService } from '@ct/shared';
import { switchMap } from 'rxjs/operators';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../my-account/constants';

@Injectable({ providedIn: 'root' })
export class MyAccountGroupTimelineDataResolver implements Resolve<GroupTimeline[]> {
  constructor(private myAccountGroupApiService: GroupApiService, private channelVideoUploadApiService: ChannelVideoUploadApiService) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot) {
    const offset = activatedRouteSnapshot.queryParams?.offset as number;
    const channelName = activatedRouteSnapshot.params?.channelName;
    return this.channelVideoUploadApiService.getChannel(channelName)
      .pipe(switchMap((channel) => this.myAccountGroupApiService.getTimeline(channel.id as string, {
      range: { limit: MY_ACCOUNT_POSTS_FEATURE_LIMIT, offset }
    })));
  }
}
