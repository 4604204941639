<ct-dialog-content titleKey="Select Entry To Share" [closeIcon]="true" headerIcon="add-circle">
  <div
    class="row mb-4 flex-column"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    <ct-journal-entry
      *ngFor="let entry of journalEntries; trackBy: trackByFn"
      class="mb-4 cursor--pointer"
      [journalEntry]="entry"
      [canEdit]="false"
      (click)="onSelect($event, entry)"
    ></ct-journal-entry>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</ct-dialog-content>
