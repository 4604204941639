<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <svg viewBox="0 0 503 503.125" id="action-edit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.05 503.125a15.93 15.93 0 0 1-11.304-4.691 15.959 15.959 0 0 1-4.203-15.168L30.578 362.71a16.026 16.026 0 0 1 4.203-7.445L372.895 17.152c22.867-22.87 60.093-22.87 82.964 0l30.164 30.164c22.872 22.872 22.872 60.098 0 82.965L147.914 468.395a15.896 15.896 0 0 1-7.445 4.203L19.914 502.633a14.784 14.784 0 0 1-3.863.492zm44.481-128.383-22.527 90.41 90.41-22.527L463.41 107.648c10.39-10.39 10.39-27.328 0-37.718l-30.164-30.164c-10.41-10.41-27.351-10.391-37.719 0zm76.074 82.348h.215zm0 0"
      />
      <path
        d="M422.688 187.008c-4.098 0-8.196-1.559-11.31-4.692L320.864 91.82c-6.254-6.254-6.254-16.386 0-22.636 6.25-6.25 16.383-6.25 22.633 0l90.52 90.52c6.25 6.25 6.25 16.382 0 22.632-3.16 3.113-7.254 4.672-11.329 4.672zM136.605 473.09c-4.097 0-8.19-1.559-11.304-4.695l-90.52-90.516c-6.25-6.254-6.25-16.387 0-22.637s16.383-6.25 22.637 0l90.516 90.52c6.25 6.25 6.25 16.383 0 22.633a15.956 15.956 0 0 1-11.329 4.695zm0 0"
      /></svg
    ><svg viewBox="0 0 14 14" id="add" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" stroke="#E17D00" stroke-linecap="round">
        <path d="M5.988 1.464v10.757" stroke-width="2.565" />
        <path d="M11.26 6.843H1.285" stroke-width="2.57" />
      </g></svg
    ><svg viewBox="0 0 20 21" id="add-circle" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2 11.333h-3.4v3.488a.81.81 0 0 1-.8.82.81.81 0 0 1-.8-.82v-3.488H5.8a.81.81 0 0 1-.8-.82.81.81 0 0 1 .8-.82h3.4V6.204a.81.81 0 0 1 .8-.82c.442 0 .8.367.8.82v3.487h3.4c.442 0 .8.368.8.82a.81.81 0 0 1-.8.821M10 .256C4.486.256 0 4.857 0 10.513c0 5.655 4.486 10.256 10 10.256s10-4.6 10-10.256C20 4.857 15.514.256 10 .256"
        fill="currentColor"
        fill-rule="evenodd"
      /></svg
    ><svg viewBox="0 0 35.418 35.418" id="amazon" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.948 9.891c-.857.068-1.847.136-2.837.269-1.516.195-3.032.461-4.284 1.053-2.439.994-4.088 3.105-4.088 6.209 0 3.898 2.506 5.875 5.669 5.875 1.057 0 1.913-.129 2.703-.328 1.255-.396 2.31-1.123 3.562-2.441.727.99.923 1.453 2.177 2.509.329.133.658.133.922-.066.791-.659 2.174-1.848 2.901-2.508.328-.267.263-.66.066-.992-.727-.924-1.45-1.718-1.45-3.498V10.03c0-2.513.195-4.822-1.647-6.537-1.518-1.391-3.891-1.916-5.735-1.916h-.792c-3.362.197-6.921 1.647-7.714 5.811-.13.525.267.726.53.793l3.691.464c.396-.07.593-.398.658-.73.333-1.449 1.518-2.176 2.836-2.309h.265c.79 0 1.646.332 2.109.987.523.795.461 1.853.461 2.775l-.003.523zm-.725 7.858c-.461.925-1.253 1.519-2.11 1.718-.131 0-.327.068-.526.068-1.45 0-2.31-1.123-2.31-2.775 0-2.11 1.254-3.104 2.836-3.565.857-.197 1.847-.265 2.836-.265v.793c-.001 1.52.061 2.707-.726 4.026zm15.195 9.169v.215c-.035 1.291-.716 3.768-2.328 5.131-.322.25-.645.107-.503-.254.469-1.145 1.541-3.803 1.04-4.412-.355-.465-1.826-.43-3.079-.322-.572.072-1.075.105-1.469.183-.357.033-.431-.287-.071-.537a5.728 5.728 0 0 1 1.541-.756c2.039-.608 4.406-.25 4.729.146.07.102.14.317.14.606zm-3.402 2.51c-.466.357-.965.682-1.468.973-3.761 2.261-8.631 3.441-12.856 3.441-6.807 0-12.895-2.512-17.514-6.709-.396-.324-.073-.789.393-.539C5.549 29.5 11.709 31.26 18.084 31.26c4.013 0 8.342-.754 12.463-2.371.285-.104.608-.252.895-.356.645-.291 1.219.432.574.895z"
      /></svg
    ><svg viewBox="0 0 48 48" id="arrow-down" xmlns="http://www.w3.org/2000/svg">
      <path d="m24 30-10-9.95h20Z" fill="currentColor" /></svg
    ><svg viewBox="0 0 48 48" id="arrow-up" xmlns="http://www.w3.org/2000/svg">
      <path d="m14 28 10-10.05L34 28Z" fill="currentColor" /></svg
    ><svg viewBox="0 0 19 26" fill="currentColor" id="article" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6H5a1 1 0 0 0 0 2h9a1 1 0 0 0 0-2ZM14 10H5a1 1 0 0 0 0 2h9a1 1 0 0 0 0-2ZM14 14H5a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2ZM14 18H5a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2Z"
      />
      <path
        d="M0 3v20c0 1.654 1.346 3 3 3h13c1.654 0 3-1.346 3-3V3c0-1.654-1.346-3-3-3H3C1.346 0 0 1.346 0 3Zm16 21H3c-.551 0-1-.448-1-1V3c0-.551.449-1 1-1h13c.551 0 1 .449 1 1v20c0 .552-.449 1-1 1Z"
      /></svg
    ><svg viewBox="0 -960 960 960" id="attach-file-add" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M640-520v-200h80v200h-80ZM440-244q-35-10-57.5-39T360-350v-370h80v476Zm30 164q-104 0-177-73t-73-177v-370q0-75 52.5-127.5T400-880q75 0 127.5 52.5T580-700v300h-80v-300q-1-42-29.5-71T400-800q-42 0-71 29t-29 71v370q-1 71 49 120.5T470-160q25 0 47.5-6.5T560-186v89q-21 8-43.5 12.5T470-80Zm170-40v-120H520v-80h120v-120h80v120h120v80H720v120h-80Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 19 28" fill="currentColor" id="book" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 24.485c0 1.366-1.01 2.478-2.25 2.478H14V2.074h.75c1.24 0 2.25 1.112 2.25 2.478v19.933ZM2 26.963V2.074h1.571v4.13c0 .66.701 1.052 1.222.687l1.921-1.346L8.636 6.89c.519.364 1.221-.027 1.221-.687v-4.13H13v24.889H2ZM8 2.074v3.111l-1.084-.957a.602.602 0 0 0-.832 0L5 5.185v-3.11h3ZM15.042 0H.792C.354 0 0 .367 0 .82v26.36c0 .453.354.82.792.82h14.25C17.224 28 19 26.16 19 23.898V4.102C19 1.84 17.224 0 15.042 0Z"
      /></svg
    ><svg viewBox="0 0 29 29" fill="currentColor" id="cabin" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14.5h2V5.179H8V14.5ZM9 2.071c.552 0 1 .465 1 1.036H8c0-.57.449-1.036 1-1.036Zm18.718 12.085L24.617 9.84l3.101-4.318a1.58 1.58 0 0 0 .253-.558c.236-.19.389-.487.389-.82 0-.572-.448-1.036-1-1.036H12C12 1.394 10.655 0 9 0 7.346 0 6 1.394 6 3.107v13.2L.122 27.468a1.055 1.055 0 0 0 .4 1.406.96.96 0 0 0 .477.126.993.993 0 0 0 .879-.54L6 20.632v1.118c0 .573.448 1.036 1 1.036.553 0 1-.463 1-1.036v-5.179h2V26.93H8v-1.036c0-.573-.447-1.036-1-1.036-.552 0-1 .463-1 1.036v2.071C6 28.537 6.448 29 7 29h4c.553 0 1-.463 1-1.036v-7.332l4.122 7.828c.182.345.525.54.879.54.162 0 .326-.04.478-.126.485-.275.664-.903.399-1.406l-5.739-10.897H15c.553 0 1-.463 1-1.035 0-.572-.447-1.036-1-1.036h-3V5.179h13.472l-2.723 3.79a1.491 1.491 0 0 0-.001 1.741l2.724 3.79H19c-.552 0-1 .464-1 1.036 0 .572.448 1.035 1 1.035h8.36c.552 0 1-.463 1-1.035a1.05 1.05 0 0 0-.388-.82 1.604 1.604 0 0 0-.254-.56Z"
      /></svg
    ><svg viewBox="0 0 21 25" id="campsite" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="akb"><use xlink:href="#aka" /></clipPath>
        <path d="M0 0h21v25H0V0Z" id="aka" />
      </defs>
      <path d="M0 0h21v25H0V0Z" fill="none" />
      <g clip-path="url(#akb)">
        <path
          d="M5.924 12.5h1.48V4.464h-1.48V12.5Zm.74-10.714c.41 0 .74.4.74.893h-1.48c0-.492.332-.893.74-.893Zm13.86 10.418-2.296-3.722 2.297-3.722c.101-.164.156-.331.187-.481A.97.97 0 0 0 21 3.57c0-.492-.332-.892-.74-.892H8.885C8.886 1.202 7.89 0 6.664 0 5.44 0 4.443 1.202 4.443 2.679v11.379L.09 23.68c-.195.433-.063.974.297 1.211A.635.635 0 0 0 .74 25c.263 0 .517-.168.65-.465l3.053-6.748v.963c0 .494.332.893.74.893.41 0 .74-.4.74-.893v-4.464h1.482v8.928H5.924v-.893c0-.493-.331-.892-.74-.892-.41 0-.741.399-.741.892v1.786c0 .494.332.893.74.893h2.962c.41 0 .74-.4.74-.893v-6.32l3.053 6.748c.135.297.389.465.65.465.12 0 .242-.035.355-.109.359-.236.492-.778.295-1.211l-4.25-9.394h2.12c.409 0 .74-.4.74-.893s-.331-.893-.74-.893H8.885V4.464h9.976l-2.017 3.267a1.46 1.46 0 0 0 0 1.502l2.017 3.267h-4.793c-.409 0-.74.4-.74.893 0 .494.331.893.74.893h6.19c.41 0 .741-.4.741-.893a.97.97 0 0 0-.287-.706 1.503 1.503 0 0 0-.188-.483Z"
        />
      </g></svg
    ><svg viewBox="0 -960 960 960" id="chat" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 24 24" id="checkbox" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V5c0-1.093-.907-2-2-2H5zm0 2h14v14H5V5z"
      /></svg
    ><svg viewBox="0 0 24 24" id="checkbox-checked" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V5c0-1.093-.907-2-2-2H5zm0 2h14v14H5V5zm11.293 3.293L10 14.586l-2.293-2.293-1.414 1.414L10 17.414l7.707-7.707-1.414-1.414z"
      /></svg
    ><svg viewBox="0 0 21 21" id="circle-cross" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 20a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19Z" fill="#FFF" fill-opacity=".351" fill-rule="evenodd" />
      <g fill="none" stroke="#5D91DA" stroke-width="2" stroke-linecap="round">
        <path d="m7.112 7.112 7.331 7.332M14.443 7.112 7.11 14.444" />
      </g></svg
    ><svg viewBox="0 0 44 43" id="circle-play" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 41.5c11.322 0 20.5-8.954 20.5-20s-9.178-20-20.5-20-20.5 8.954-20.5 20 9.178 20 20.5 20Z"
        fill="none"
        stroke="#FFF"
        stroke-width="3"
      />
      <path d="m28 21-11 6V15l11 6Z" fill="#FFF" fill-rule="evenodd" /></svg
    ><svg viewBox="0 0 47.971 47.971" xml:space="preserve" id="close" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.228 23.986 47.092 5.122a2.998 2.998 0 0 0 0-4.242 2.998 2.998 0 0 0-4.242 0L23.986 19.744 5.121.88a2.998 2.998 0 0 0-4.242 0 2.998 2.998 0 0 0 0 4.242l18.865 18.864L.879 42.85a2.998 2.998 0 1 0 4.242 4.241l18.865-18.864L42.85 47.091c.586.586 1.354.879 2.121.879s1.535-.293 2.121-.879a2.998 2.998 0 0 0 0-4.242L28.228 23.986z"
      /></svg
    ><svg viewBox="0 -960 960 960" id="cloud_download" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M251-160q-88 0-149.5-61.5T40-371q0-79 50.5-137.5T217-579q15-84 82-148.5T451-792q24 0 42 13.5t18 36.5v294l83-83 43 43-156 156-156-156 43-43 83 83v-289q-86 11-135 75.5T267-522h-19q-61 0-104.5 43T100-371q0 65 45 108t106 43h500q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-68-33-117.5T570-718v-65q81 29 129.5 101T748-522v24q72-2 122 46t50 123q0 69-50 119t-119 50H251Zm229-347Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 17 22" id="collapse" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        stroke-width="1.2"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9.571 1H14a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H9.571h0M12.786 11H1M5.286 6 1 11l4.286 5" />
      </g></svg
    ><svg viewBox="0 0 29 25" id="collections" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fill-rule="nonzero">
        <path d="M15 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4.571a1.571 1.571 0 1 1 0-3.143 1.571 1.571 0 1 1 0 3.143Z" />
        <path
          d="M26.483 2.173 7.255.026a2.672 2.672 0 0 0-2.077.589 2.622 2.622 0 0 0-1.021 1.8l-.353 2.841H2.713C1.163 5.256 0 6.607 0 8.131v14.167c-.04 1.453 1.127 2.663 2.605 2.701l.107.001h19.333c1.55 0 2.958-1.178 2.958-2.702v-.554c.48-.091.936-.28 1.339-.554a2.862 2.862 0 0 0 1.02-1.87l1.62-14.064c.166-1.527-.949-2.902-2.5-3.083Zm-2.887 20.125c0 .762-.775 1.316-1.55 1.316H2.713a1.259 1.259 0 0 1-1.303-1.316v-2.563l5.458-3.949a1.712 1.712 0 0 1 2.184.104l3.838 3.325c.583.482 1.317.75 2.078.762a3.245 3.245 0 0 0 1.69-.45l6.938-3.949v6.72Zm0-8.348-7.677 4.4a1.82 1.82 0 0 1-2.078-.174l-3.874-3.36a3.175 3.175 0 0 0-3.909-.138L1.41 18.003V8.13c0-.762.528-1.49 1.303-1.49h19.333a1.608 1.608 0 0 1 1.55 1.49v5.82Zm3.98-8.88v.013L25.92 19.146a1.17 1.17 0 0 1-.458.935c-.14.139-.458.208-.458.277V8.131c-.055-1.578-1.353-2.84-2.958-2.875H5.213l.317-2.702c.069-.35.255-.666.528-.9a1.64 1.64 0 0 1 1.057-.277l19.192 2.182a1.392 1.392 0 0 1 1.27 1.51Z"
        />
      </g></svg
    ><svg viewBox="0 0 51 52" id="create" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.84 29.66h-9.18v9.18a2.16 2.16 0 0 1-4.32 0v-9.18h-9.18a2.16 2.16 0 0 1 0-4.32h9.18v-9.18a2.16 2.16 0 0 1 4.32 0v9.18h9.18a2.16 2.16 0 0 1 0 4.32M25.5 0C11.439 0 0 11.663 0 26s11.439 26 25.5 26S51 40.337 51 26 39.561 0 25.5 0"
      /></svg
    ><svg viewBox="0 0 25 25" id="dashboard" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M9.25 9h-7.5C.785 9 0 8.117 0 7.031V1.97C0 .883.785 0 1.75 0h7.5C10.215 0 11 .883 11 1.969V7.03C11 8.117 10.215 9 9.25 9Zm-7.5-7.313c-.138 0-.25.127-.25.282V7.03c0 .155.112.282.25.282h7.5c.138 0 .25-.127.25-.282V1.97c0-.155-.112-.282-.25-.282h-7.5ZM9.25 25h-7.5C.785 25 0 24.159 0 23.125v-11.25C0 10.841.785 10 1.75 10h7.5c.965 0 1.75.841 1.75 1.875v11.25C11 24.16 10.215 25 9.25 25Zm-7.5-13.393c-.138 0-.25.12-.25.268v11.25c0 .148.112.268.25.268h7.5c.138 0 .25-.12.25-.268v-11.25c0-.148-.112-.268-.25-.268h-7.5ZM23.25 25h-7.5c-.965 0-1.75-.883-1.75-1.969V17.97c0-1.086.785-1.969 1.75-1.969h7.5c.965 0 1.75.883 1.75 1.969v5.062C25 24.117 24.215 25 23.25 25Zm-7.5-7.313c-.138 0-.25.127-.25.282v5.062c0 .155.112.282.25.282h7.5c.138 0 .25-.127.25-.282V17.97c0-.155-.112-.282-.25-.282h-7.5ZM23.25 15h-7.5c-.965 0-1.75-.841-1.75-1.875V1.875C14 .84 14.785 0 15.75 0h7.5C24.215 0 25 .841 25 1.875v11.25C25 14.159 24.215 15 23.25 15Zm-7.5-13.393c-.138 0-.25.12-.25.268v11.25c0 .148.112.268.25.268h7.5c.138 0 .25-.12.25-.268V1.875c0-.148-.112-.268-.25-.268h-7.5Z"
        />
      </g></svg
    ><svg viewBox="0 0 48 48" id="delete" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.05 42q-1.25 0-2.125-.875T10.05 39V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 24 24" id="down" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Layer 2">
        <path
          d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"
          data-name="chevron-down"
        />
      </g></svg
    ><svg viewBox="0 0 37 36" id="edit" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path
          d="M28.235 23.221c-.976 0-1.764.8-1.764 1.79v5.62c0 .988-.79 1.79-1.765 1.79H5.294c-.974 0-1.765-.802-1.765-1.79V7.369c0-.987.791-1.79 1.765-1.79h10.253c.976 0 1.765-.799 1.765-1.789 0-.99-.79-1.789-1.765-1.789H5.294C2.375 2 0 4.409 0 7.368v23.264C0 33.592 2.375 36 5.294 36h19.412C27.625 36 30 33.593 30 30.632V25.01c0-.99-.789-1.79-1.765-1.79Z"
        />
        <path
          d="M35.039 1.953a6.682 6.682 0 0 0-4.61-1.951c-1.763-.039-3.537.6-4.87 1.935L13.688 13.814A9.158 9.158 0 0 0 11 20.316v3.93c0 .97.784 1.754 1.754 1.754h3.928c2.452 0 4.76-.954 6.501-2.69L35.04 11.45c2.614-2.616 2.614-6.879-.001-9.496ZM20.705 20.828a5.666 5.666 0 0 1-4.023 1.663h-2.174v-2.175c0-1.518.59-2.95 1.66-4.023l7.827-7.83L28.53 13l-7.825 7.828ZM32.56 8.97l-1.549 1.55-4.535-4.538 1.549-1.549a3.21 3.21 0 0 1 4.535 0 3.216 3.216 0 0 1 0 4.537Z"
        />
      </g></svg
    ><svg viewBox="0 0 19 19" id="exit" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h19v17.273H0V0Z" fill="none" />
      <g clip-path="url(#mask_1)">
        <path
          d="M7.606 4.467v2.194H0v3.95h7.606v2.195l5.357-4.17-5.357-4.169ZM4.84 0C3.285 0 2.033 1.275 2.033 2.858v2.894h1.816V2.858c0-.59.41-1.009.991-1.009h11.353c.58 0 .99.418.99 1.009v11.557c0 .59-.41 1.009-.99 1.009H4.84c-.58 0-.99-.418-.99-1.01v-2.893H2.032v2.894c0 1.583 1.252 2.858 2.807 2.858h11.353c1.555 0 2.807-1.275 2.807-2.858V2.858C19 1.275 17.748 0 16.193 0H4.84Z"
          fill="#7E7E7E"
          fill-rule="evenodd"
        />
      </g></svg
    ><svg viewBox="0 0 17 22" id="expand" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        stroke-width="1.2"
        fill="none"
        fill-rule="evenodd"
        stroke-dasharray="0,0"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M7.429 21H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h4.429M4.214 11H16M11.714 16 16 11l-4.286-5" />
      </g></svg
    ><svg viewBox="0 -960 960 960" id="explore" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m263-263 290-143 143-290-290 143-143 290Zm217-177q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm0 360q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
      /></svg
    ><svg viewBox="0 0 52 52" id="facebook" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 51c13.807 0 25-11.193 25-25S39.807 1 26 1 1 12.193 1 26s11.193 25 25 25Z"
        fill="inherit"
        fill-rule="evenodd"
      />
      <path
        d="M20.559 26.282h2.276v9.317c0 .184.15.333.335.333h3.86a.334.334 0 0 0 .334-.333v-9.273h2.617c.17 0 .313-.127.332-.295l.398-3.43a.331.331 0 0 0-.333-.371h-3.014v-2.15c0-.649.351-.977 1.044-.977h1.97c.185 0 .335-.15.335-.333V15.62a.334.334 0 0 0-.335-.333h-2.716c-.019 0-.061-.002-.124-.002-.471 0-2.11.092-3.403 1.275-1.434 1.312-1.235 2.882-1.187 3.154v2.515h-2.39a.334.334 0 0 0-.334.333v3.386c0 .184.15.333.335.333Z"
        fill="#FFF"
      /></svg
    ><svg viewBox="0 0 48 48" id="flag" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 42V8h17.15l.95 4.3H40v18.5H27.2l-.95-4.25H13V42Zm15-22.6Zm4.75 8.4H37V15.3H25.55L24.6 11H13v12.55h15.8Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 29 24" id="gallery" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 8.64c-1.657 0-3 1.29-3 2.88 0 1.59 1.343 2.88 3 2.88s3-1.29 3-2.88c0-1.59-1.343-2.88-3-2.88Zm0 4.388c-.868 0-1.571-.675-1.571-1.508S14.132 10.01 15 10.01s1.571.676 1.571 1.509c0 .833-.703 1.508-1.571 1.508Z"
      />
      <path
        d="M26.483 2.086 7.255.025A2.75 2.75 0 0 0 5.178.59a2.496 2.496 0 0 0-1.021 1.73l-.353 2.726H2.713C1.163 5.046 0 6.343 0 7.806v13.6c-.04 1.395 1.127 2.556 2.605 2.593H22.046c1.55 0 2.958-1.13 2.958-2.593v-.532c.48-.088.936-.269 1.339-.532a2.72 2.72 0 0 0 1.02-1.796l1.62-13.5c.166-1.467-.949-2.786-2.5-2.96Zm-2.887 19.32c0 .732-.775 1.264-1.55 1.264H2.713c-.7.02-1.284-.5-1.305-1.162 0-.034 0-.068.002-.102v-2.46l5.458-3.791a1.767 1.767 0 0 1 2.184.1l3.838 3.192c.583.462 1.317.72 2.078.731a3.35 3.35 0 0 0 1.69-.432l6.938-3.79v6.45Zm0-8.014-7.677 4.223a1.881 1.881 0 0 1-2.078-.166l-3.874-3.225a3.28 3.28 0 0 0-3.909-.133L1.41 17.282V7.806c0-.732.528-1.43 1.303-1.43h19.333c.828.032 1.496.65 1.55 1.43v5.586Zm3.98-8.526v.014l-1.656 13.5c.003.35-.166.682-.458.898-.14.133-.458.2-.458.266V7.806c-.055-1.515-1.353-2.726-2.958-2.76H5.213l.317-2.594c.069-.336.255-.64.528-.864a1.692 1.692 0 0 1 1.057-.266l19.192 2.095c.775.069 1.343.718 1.27 1.45Z"
      /></svg
    ><svg viewBox="0 0 48 48" id="grid" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 42q-1.25 0-2.125-.875T6 39V9q0-1.25.875-2.125T9 6h30q1.25 0 2.125.875T42 9v30q0 1.25-.875 2.125T39 42Zm16.5-16.5V39H39V25.5Zm0-3H39V9H25.5Zm-3 0V9H9v13.5Zm0 3H9V39h13.5Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 35 29" id="groups" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M29.873 15.58h-3.426c-.466 0-.918.062-1.347.177A5.143 5.143 0 0 0 20.528 13h-6.056A5.143 5.143 0 0 0 9.9 15.757a5.206 5.206 0 0 0-1.347-.177H5.127C2.3 15.58 0 17.836 0 20.61v5.372C0 27.646 1.38 29 3.076 29h28.848C33.62 29 35 27.646 35 25.982V20.61c0-2.774-2.3-5.03-5.127-5.03ZM9.345 18.03v8.958H3.076a1.017 1.017 0 0 1-1.025-1.006V20.61c0-1.664 1.38-3.018 3.076-3.018h3.426c.278 0 .548.037.805.105-.008.11-.013.221-.013.333Zm14.26 8.958h-12.21V18.03c0-1.664 1.38-3.018 3.077-3.018h6.056c1.696 0 3.076 1.354 3.076 3.018v8.958Zm9.344-1.006c0 .555-.46 1.006-1.025 1.006h-6.27V18.03c0-.112-.004-.223-.012-.333.257-.068.527-.105.805-.105h3.426c1.696 0 3.076 1.354 3.076 3.018v5.372Z"
        />
        <path
          d="M6.5 6A4.505 4.505 0 0 0 2 10.5C2 12.981 4.019 15 6.5 15s4.5-2.019 4.5-4.5S8.981 6 6.5 6Zm0 6.974A2.477 2.477 0 0 1 4.026 10.5 2.477 2.477 0 0 1 6.5 8.026 2.477 2.477 0 0 1 8.974 10.5 2.477 2.477 0 0 1 6.5 12.974ZM17 0c-3.308 0-6 2.692-6 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6Zm0 9.978A3.983 3.983 0 0 1 13.022 6 3.983 3.983 0 0 1 17 2.022 3.983 3.983 0 0 1 20.978 6 3.983 3.983 0 0 1 17 9.978ZM28.5 6a4.505 4.505 0 0 0-4.5 4.5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5S30.981 6 28.5 6Zm0 6.974a2.477 2.477 0 0 1-2.474-2.474A2.477 2.477 0 0 1 28.5 8.026a2.477 2.477 0 0 1 2.474 2.474 2.477 2.477 0 0 1-2.474 2.474Z"
        />
      </g></svg
    ><svg viewBox="0 0 31 31" id="help" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path
          d="M15.5 30C7.492 30 1 23.508 1 15.5S7.492 1 15.5 1 30 7.492 30 15.5 23.508 30 15.5 30Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="0,0"
        />
        <path d="M15.5 24.272a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6Z" fill="currentColor" />
        <path
          d="M11.933 10.05a3.699 3.699 0 0 1 7.135 1.408c0 3.47-3.699 3.895-3.699 5.204v1.03"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="0,0"
        />
      </g></svg
    ><svg viewBox="0 0 52 52" id="instagram" xmlns="http://www.w3.org/2000/svg">
      <g fill-rule="evenodd">
        <path d="M26 51c13.807 0 25-11.193 25-25S39.807 1 26 1 1 12.193 1 26s11.193 25 25 25Z" fill="inherit" />
        <path
          d="M33.724 19.866a1.59 1.59 0 1 1-3.18 0 1.59 1.59 0 0 1 3.18 0M26.227 30.09a3.862 3.862 0 1 1 0-7.724 3.862 3.862 0 0 1 0 7.724m0-9.996a6.134 6.134 0 1 0 0 12.268 6.134 6.134 0 0 0 0-12.268M26 14.187c-3.208 0-3.61.013-4.87.07-1.258.058-2.117.258-2.868.55a5.79 5.79 0 0 0-2.092 1.363 5.789 5.789 0 0 0-1.363 2.092c-.292.751-.492 1.61-.55 2.867-.057 1.26-.07 1.663-.07 4.871s.013 3.61.07 4.87c.058 1.258.258 2.117.55 2.868a5.79 5.79 0 0 0 1.363 2.092 5.791 5.791 0 0 0 2.092 1.363c.751.292 1.61.492 2.867.55 1.26.057 1.663.07 4.871.07s3.61-.013 4.87-.07c1.258-.058 2.117-.258 2.868-.55a5.791 5.791 0 0 0 2.093-1.363 5.794 5.794 0 0 0 1.362-2.092c.292-.751.492-1.61.55-2.867.057-1.26.07-1.663.07-4.871s-.013-3.61-.07-4.87c-.058-1.258-.258-2.117-.55-2.868a5.792 5.792 0 0 0-1.362-2.092 5.79 5.79 0 0 0-2.093-1.363c-.751-.292-1.61-.492-2.867-.55-1.26-.057-1.663-.07-4.871-.07m0 2.272c3.108 0 3.476.011 4.703.067 1.135.052 1.75.242 2.161.401.543.211.931.464 1.338.87.408.408.66.796.87 1.339.16.41.35 1.026.402 2.161.056 1.227.067 1.595.067 4.703 0 3.108-.011 3.476-.067 4.703-.052 1.135-.242 1.75-.401 2.161-.211.543-.463.931-.87 1.338-.408.408-.796.66-1.339.87-.41.16-1.026.35-2.161.402-1.227.056-1.595.067-4.703.067-3.108 0-3.476-.011-4.703-.067-1.135-.052-1.75-.242-2.16-.401a3.607 3.607 0 0 1-1.34-.87 3.608 3.608 0 0 1-.87-1.339c-.16-.41-.349-1.026-.4-2.161-.057-1.227-.068-1.595-.068-4.703 0-3.108.011-3.476.068-4.703.051-1.135.24-1.75.4-2.161.211-.543.464-.931.87-1.338.408-.407.796-.66 1.34-.87.41-.16 1.025-.35 2.16-.402 1.227-.056 1.595-.067 4.703-.067"
          fill="#FFF"
        />
      </g></svg
    ><svg fill="none" viewBox="0 0 72 97" id="lantern" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.472 87.735H6.63C2.972 87.735 0 84.79 0 81.168v-65.09C0 12.456 2.972 9.51 6.629 9.51h57.828c3.656 0 6.629 2.945 6.629 6.567v65.09c.015 3.622-2.958 6.567-6.614 6.567ZM6.63 11.705c-2.438 0-4.415 1.958-4.415 4.373v65.09c0 2.415 1.977 4.373 4.415 4.373h57.828c2.438 0 4.414-1.958 4.414-4.373v-65.09c0-2.415-1.976-4.373-4.414-4.373H6.63Z"
        fill="#614D44"
      />
      <path d="m29.486 16.902-7.371-9.864L35.312 0v21.216l-5.826-4.314Z" fill="#93932F" />
      <path d="m41.153 16.902 7.372-9.864L35.313 0v21.216l5.84-4.314Z" fill="#626208" />
      <path
        d="M50.695 94.375H19.93c-6.093 0-11.028-4.888-11.028-10.925V25.59c0-6.037 4.935-10.925 11.028-10.925h30.765c6.093 0 11.028 4.888 11.028 10.925V83.45c0 6.037-4.935 10.925-11.028 10.925Z"
        fill="#A4AB18"
      />
      <path
        d="M50.695 14.664H35.312v79.71h15.383c6.093 0 11.028-4.887 11.028-10.924V25.59c0-6.022-4.935-10.925-11.028-10.925Z"
        fill="#76770B"
      />
      <path d="M35.313 25.589H8.902v53.15h26.41V25.59Z" fill="#74F8FD" />
      <path d="M61.723 25.589h-26.41v53.15h26.41V25.59Z" fill="#6CDFF1" />
      <path
        d="M38.404 86.645c0 1.694-1.382 3.048-3.077 3.048-1.709 0-3.076-1.37-3.076-3.048 0-1.693 1.382-3.047 3.077-3.047a3.05 3.05 0 0 1 3.076 3.047Z"
        fill="#FBD22A"
      />
      <path d="M22.115 7.038h13.197V0L22.115 7.038Z" fill="#A4AB18" />
      <path d="m35.313 0 13.212 7.038H35.313V0Z" fill="#76770B" />
      <path
        d="M33.886 78.37s-11.504.398-14.595-9.039c-3.091-9.438 0-12.839 0-12.839s2.987 8.687 6.614 8.245c3.626-.441-3.954-10.394 2.288-21.054l6.242-10.644s2.14 9.364 7.476 12.735c5.336 3.372 7.64 13.545 4.771 18.963-2.868 5.419 6.495-3.577 6.495-3.577s-.134 20.553-19.291 17.21Z"
        fill="#F07B20"
      />
      <path
        d="M33.886 78.371s4.92-.397 6.316-7.126c1.412-6.728-3.73-17.417-4.681-20.2-.952-2.783 2.11 17.182-1.65 18.345-3.76 1.163-3.76.236-3.76.236s-1.383 6.595 3.775 8.745Z"
        fill="#EF651F"
      />
      <path
        d="M35.313 96.26H14.43a.953.953 0 0 1-.951-.943c0-.515.431-.942.951-.942h20.896v1.885h-.014Z"
        fill="#6B4E32"
      />
      <path d="M35.313 94.375h20.896c.52 0 .95.427.95.942a.953.953 0 0 1-.95.943H35.312v-1.885Z" fill="#493127" />
      <path d="m9.1 25.397-.392.382 52.817 53.15.392-.381L9.099 25.397Z" fill="#6DAA3E" />
      <path d="M61.938 25.81 8.3 78.148l.386.388L62.324 26.2l-.386-.389Z" fill="#6DAA3E" /></svg
    ><svg viewBox="0 0 48 48" id="left" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.35 42.6 13.8 25.05q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.55.1-.25.35-.5L31.35 5.4q.55-.55 1.4-.55.85 0 1.4.55.6.6.6 1.425 0 .825-.6 1.425L18.4 24l15.75 15.75q.65.65.6 1.45-.05.8-.6 1.35-.6.6-1.425.6-.825 0-1.375-.55Z"
      /></svg
    ><svg viewBox="0 0 37 37" id="left-arrow-square" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M8.452 17h16.096c.802 0 1.452.672 1.452 1.5s-.65 1.5-1.452 1.5H8.452C7.65 20 7 19.328 7 18.5S7.65 17 8.452 17Z"
        />
        <path
          d="M21.545 10c.41-.001.804.154 1.094.43l6.568 6.245a2.496 2.496 0 0 1 0 3.65l-6.568 6.245a1.6 1.6 0 0 1-1.493.38 1.51 1.51 0 0 1-1.092-1.04 1.42 1.42 0 0 1 .4-1.419l6.3-5.991-6.302-5.992a1.417 1.417 0 0 1-.334-1.6c.239-.55.802-.908 1.427-.908Z"
        />
        <path
          d="M7.371 0H29.63A7.38 7.38 0 0 1 37 7.371V29.63A7.38 7.38 0 0 1 29.629 37H7.37A7.38 7.38 0 0 1 0 29.629V7.37A7.38 7.38 0 0 1 7.371 0ZM29.63 34.11a4.486 4.486 0 0 0 4.48-4.481V7.37a4.486 4.486 0 0 0-4.48-4.48H7.37a4.486 4.486 0 0 0-4.48 4.48V29.63a4.486 4.486 0 0 0 4.48 4.48H29.63Z"
        />
      </g></svg
    ><svg viewBox="0 0 24 24" fill="none" id="like" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.944 19H5.056A1.056 1.056 0 0 1 4 17.944v-7.388C4 9.973 4.473 9.5 5.056 9.5h1.888C7.527 9.5 8 9.973 8 10.556v7.388C8 18.527 7.527 19 6.944 19v0ZM8 10.572l3.649-4.751a1.734 1.734 0 0 1 2.714-.046c.265.321.409.725.409 1.141v3.271h3.096a1.8 1.8 0 0 1 1.496.799l.329.491a1.8 1.8 0 0 1 .234 1.499l-1.359 4.722A1.8 1.8 0 0 1 16.839 19H10.55a1.8 1.8 0 0 1-1.318-.574L8 17.1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      /></svg
    ><svg viewBox="0 0 48 48" id="list" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.55 39q-1.05 0-1.8-.725T6 36.55q0-1.05.75-1.8t1.8-.75q1 0 1.725.75.725.75.725 1.8 0 1-.725 1.725Q9.55 39 8.55 39ZM16 38v-3h26v3ZM8.55 26.5q-1.05 0-1.8-.725T6 24q0-1.05.75-1.775.75-.725 1.8-.725 1 0 1.725.75Q11 23 11 24t-.725 1.75q-.725.75-1.725.75Zm7.45-1v-3h26v3ZM8.5 14q-1.05 0-1.775-.725Q6 12.55 6 11.5q0-1.05.725-1.775Q7.45 9 8.5 9q1.05 0 1.775.725Q11 10.45 11 11.5q0 1.05-.725 1.775Q9.55 14 8.5 14Zm7.5-1v-3h26v3Z"
        fill="currentColor"
      /></svg
    ><svg stroke="currentColor" viewBox="0 0 44 44" id="loading" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd" stroke-width="2">
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            dur="1.8s"
            keySplines="0.165, 0.84, 0.44, 1"
            keyTimes="0; 1"
            repeatCount="indefinite"
            values="1; 20"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            calcMode="spline"
            dur="1.8s"
            keySplines="0.3, 0.61, 0.355, 1"
            keyTimes="0; 1"
            repeatCount="indefinite"
            values="1; 0"
          />
        </circle>
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="-0.9s"
            calcMode="spline"
            dur="1.8s"
            keySplines="0.165, 0.84, 0.44, 1"
            keyTimes="0; 1"
            repeatCount="indefinite"
            values="1; 20"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            calcMode="spline"
            dur="1.8s"
            keySplines="0.3, 0.61, 0.355, 1"
            keyTimes="0; 1"
            repeatCount="indefinite"
            values="1; 0"
          />
        </circle>
      </g></svg
    ><svg viewBox="0 -960 960 960" fill="currentColor" id="location-on" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"
      /></svg
    ><svg fill="none" viewBox="0 0 116 104" id="marketplace-tent" xmlns="http://www.w3.org/2000/svg">
      <path d="m58 2.722 20.983 5.28L58 13.263V2.722Z" fill="#FACB46" />
      <path d="M78.983 8.001 58 13.264V8.001h20.983Z" fill="#E2B63A" />
      <path
        d="M58 20.282a.604.604 0 0 1-.604-.607V.607a.604.604 0 1 1 1.208 0v19.068a.594.594 0 0 1-.604.607Z"
        fill="#B5793A"
      />
      <path d="M58 16.019v70.437H12.165l-.261-.442-5.797-9.56C6.09 76.455 48.105 46.86 58 16.02Z" fill="#A4AB18" />
      <path
        d="M12.916 78.717c.719 3.427 0 7.756 0 7.756l-1.028-.443-5.797-9.559S48.089 46.86 58 16.035C51.73 49.073 18.304 75.291 12.915 78.717Z"
        fill="#B8B824"
      />
      <path d="M58 16.019v70.437h45.835l.261-.442 5.797-9.56c.016 0-41.998-29.594-51.893-60.435Z" fill="#76770B" />
      <path d="m42.749 86.473 3.02-9.56 8.084-18.346L58 42.794l6.63 22.577L80.6 86.473H42.748Z" fill="#07230C" />
      <path d="M58 42.794s9.144 35.3 30.486 43.662H73.235S61.609 77.816 58 42.794Z" fill="#626208" />
      <path
        d="M103.084 78.717c-.719 3.427 0 7.756 0 7.756l1.029-.443 5.796-9.559S67.912 46.86 58 16.035c6.27 33.038 39.695 59.256 45.084 62.682Z"
        fill="#93932F"
      />
      <path d="M58 42.794s-9.144 35.3-30.486 43.662h15.251S54.391 77.816 58 42.794Z" fill="#76770B" />
      <path
        d="M8.426 87.08a.595.595 0 0 1-.588-.46L4.066 71.29a.606.606 0 1 1 1.176-.295l3.772 15.33a.606.606 0 0 1-.441.738c-.05.016-.098.016-.147.016ZM107.281 87.08c-.049 0-.098 0-.147-.017a.606.606 0 0 1-.441-.738l3.772-15.33a.606.606 0 1 1 1.175.295l-3.771 15.33a.614.614 0 0 1-.588.46Z"
        fill="#B5793A"
      />
      <path
        d="M115.2 86.473H.8c-1.29 3.46-1.322 7.263 2.22 9.968 3.74 2.853 8.329 1.64 12.95 2.083 8.18.77 15.283 4.312 23.79 3.23 8.492-1.082 13.096 1.443 18.24.951 5.144.492 9.748-2.033 18.24-.951 8.507 1.082 15.626-2.46 23.807-3.23 4.637-.443 9.226.77 12.949-2.083 3.527-2.721 3.494-6.509 2.204-9.968Z"
        fill="#6B4E32"
      />
      <path
        d="M115.184 86.473c0-.017 0-.017-.017-.033-1.273 0-2.547.016-3.821.016.229 1.771.392 3.771-.8 5.1-1.42 1.573-3.902 1.295-6.009 1.164-5.486-.345-10.908 1.213-16.247 2.508-5.617 1.361-11.283 2.476-17.031 3.115-9.405 1.05-18.925.787-28.33-.278-5.683-.64-11.333-1.59-16.868-3.05-4.278-1.131-8.59-2.558-13.014-2.377-1.552.065-3.103.328-4.638.163-2.27-.245-3.755-1.328-3.87-3.607-.049-.885 0-1.77.05-2.64v-.114H.8c-1.29 3.46-1.322 7.263 2.22 9.969 3.74 2.853 8.329 1.64 12.95 2.082 8.18.77 15.283 4.312 23.79 3.23 8.492-1.082 13.096 2.705 18.24 2.213 5.144.492 9.748-3.295 18.24-2.213 8.507 1.082 15.626-2.46 23.807-3.23 4.637-.443 9.226.77 12.949-2.082 3.314-2.509 3.412-6.264 2.188-9.936Z"
        fill="#493127"
      />
      <path
        d="M36.756 22.84c-.522-.968-1.339-1.706-2.319-2.181-.914-.443-2.498-.246-3.249-.803-.196-.148-.326-.378-.457-.574-.67-1-1.796-1.69-3.005-1.788-.62-.049-2.71.656-3.102.165-1.633-2.116-4.131-3.558-6.777-3.903-3.56-.459-7.38.836-8.72 4.362a6.649 6.649 0 0 0-1.012.049c-1.094.13-2.139.459-3.037 1.115-1.698 1.246-2.792 3.295-2.694 5.427h34.6a2.583 2.583 0 0 0-.228-1.87Z"
        fill="#6CDFF1"
      />
      <path
        d="M30.143 27.742c.033-.885-.343-1.672-.93-2.394-1.225-1.54-2.76-2.738-4.785-2.902a4.798 4.798 0 0 0-1.927.23c-.098-.115-.196-.23-.31-.345-1.224-1.213-3.07-1.738-4.735-1.279-1.176.328-2.107 1.116-2.727 2.116-2.613-.41-4.376 1.623-5.242 3.918-.081.213-.13.443-.147.673h20.803v-.017Z"
        fill="#50C8E7"
      /></svg
    ><svg viewBox="0 0 24 24" id="menu" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 6H0V2h24v4zm0 4H0v4h24v-4zm0 8H0v4h24v-4z" /></svg
    ><svg viewBox="0 -960 960 960" id="more" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 240.823 240.823" xml:space="preserve" id="nav" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M183.19 111.82 74.89 3.56c-4.752-4.74-12.451-4.74-17.215 0-4.752 4.74-4.752 12.439 0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439 0 17.191 4.752 4.74 12.463 4.74 17.215 0l108.3-108.26c4.68-4.691 4.68-12.511-.012-17.19z"
        fill="#fff"
      /></svg
    ><svg viewBox="2 2 20 20" preserveAspectRatio="none" id="no-avatar" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z"
      /></svg
    ><svg viewBox="0 0 24 24" fill="none" id="notification" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.02 20.53c-2.33 0-4.66-.37-6.87-1.11-.84-.29-1.48-.88-1.76-1.65-.29-.77-.19-1.62.27-2.38l1.15-1.91c.24-.4.46-1.2.46-1.67V8.92c0-3.72 3.03-6.75 6.75-6.75s6.75 3.03 6.75 6.75v2.89c0 .46.22 1.27.46 1.68l1.14 1.9c.43.72.51 1.59.22 2.38a2.72 2.72 0 0 1-1.71 1.65c-2.2.74-4.53 1.11-6.86 1.11Zm0-16.86c-2.89 0-5.25 2.35-5.25 5.25v2.89c0 .73-.3 1.81-.67 2.44l-1.15 1.91c-.22.37-.28.76-.15 1.09.12.34.42.6.83.74a20 20 0 0 0 12.79 0c.36-.12.64-.39.77-.75s.1-.75-.1-1.08l-1.15-1.91c-.38-.65-.67-1.72-.67-2.45V8.92c0-2.9-2.35-5.25-5.25-5.25Z"
        fill="currentColor"
      />
      <path
        d="M13.88 3.94c-.07 0-.14-.01-.21-.03-.29-.08-.57-.14-.84-.18-.85-.11-1.67-.05-2.44.18-.28.09-.58 0-.77-.21a.742.742 0 0 1-.14-.78 2.724 2.724 0 0 1 2.55-1.74c1.14 0 2.14.68 2.55 1.74.1.27.05.57-.14.78-.15.16-.36.24-.56.24ZM12.02 22.81c-.99 0-1.95-.4-2.65-1.1-.7-.7-1.1-1.66-1.1-2.65h1.5c0 .59.24 1.17.66 1.59.42.42 1 .66 1.59.66 1.24 0 2.25-1.01 2.25-2.25h1.5c0 2.07-1.68 3.75-3.75 3.75Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 48 48" id="photo" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.575 48H6.425A6.425 6.425 0 0 1 0 41.575V6.425A6.425 6.425 0 0 1 6.425 0h35.15A6.425 6.425 0 0 1 48 6.425v35.15A6.425 6.425 0 0 1 41.575 48ZM6.425 3.692a2.732 2.732 0 0 0-2.733 2.733v35.15a2.732 2.732 0 0 0 2.733 2.733h35.15a2.732 2.732 0 0 0 2.733-2.733V6.425a2.732 2.732 0 0 0-2.733-2.733H6.425Z"
        fill="currentColor"
      />
      <path
        d="M1.841 41a1.873 1.873 0 0 1-1.403-.639A1.837 1.837 0 0 1 .66 37.76l12.601-10.602a4.933 4.933 0 0 1 6.646.27l5.98 5.963c.461.393 1.14.393 1.6 0l5.809-4.196a4.934 4.934 0 0 1 6.005 0l8 6.356a1.849 1.849 0 0 1-.478 3.171 1.86 1.86 0 0 1-1.836-.275l-7.975-6.381a1.234 1.234 0 0 0-1.501 0l-5.809 4.491a4.933 4.933 0 0 1-6.423-.441l-5.981-6.087a1.233 1.233 0 0 0-1.65 0L3.024 40.582c-.33.278-.75.427-1.182.418ZM31 24a7 7 0 1 1 0-14 7 7 0 0 1 0 14Zm0-10.182a3.182 3.182 0 1 0 0 6.364 3.182 3.182 0 0 0 0-6.364Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 79 79" id="play" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 3">
        <path data-name="Path 5" d="m49.343 39.25-14.579-8.971v17.943z" fill="currentColor" />
        <g data-name="Path 252" style="fill: none">
          <path d="M39.5 0A39.5 39.5 0 1 1 0 39.5 39.5 39.5 0 0 1 39.5 0z" style="stroke: none" />
          <path
            d="M39.5 8C22.13 8 8 22.13 8 39.5 8 56.87 22.13 71 39.5 71 56.87 71 71 56.87 71 39.5 71 22.13 56.87 8 39.5 8m0-8C61.315 0 79 17.685 79 39.5S61.315 79 39.5 79 0 61.315 0 39.5 17.685 0 39.5 0z"
            style="stroke: none"
            fill="currentColor"
          />
        </g>
      </g></svg
    ><svg viewBox="0 0 24 24" id="remove" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg
    ><svg viewBox="0 0 24 24" id="right" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Layer 2">
        <path
          d="M10.5 17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42L13.1 12 9.92 8.69a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l3.86 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-.7.32z"
          data-name="chevron-right"
        />
      </g></svg
    ><svg viewBox="0 -960 960 960" fill="currentColor" id="robot" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M193-397q-33 0-55.5-22.62T115-474.41q0-32.17 23.13-54.88Q161.25-552 193-552v-102q0-26.25 20.38-46.63Q233.75-721 260-721h143q0-32 22.83-54.5 22.82-22.5 54.29-22.5 31.46 0 54.17 22.79T557-721h143q26.25 0 46.63 20.37Q767-680.25 767-654v102q32 0 55 22.79t23 54.33q0 32.37-22.79 55.12Q799.42-397 767-397v179q0 26.89-20.37 46.94Q726.25-151 700-151H260q-26.25 0-46.62-20.06Q193-191.11 193-218v-179Zm161.18-68q14.24 0 24.53-9.97Q389-484.94 389-499.18t-10.28-24.53Q368.45-534 353.76-534q-14.26 0-24.01 10.28-9.75 10.27-9.75 24.96 0 14.26 9.97 24.01 9.97 9.75 24.21 9.75Zm252 0q14.24 0 24.53-9.97Q641-484.94 641-499.18t-10.28-24.53Q620.45-534 605.76-534q-14.26 0-24.01 10.28-9.75 10.27-9.75 24.96 0 14.26 9.97 24.01 9.97 9.75 24.21 9.75ZM336-294h288v-35H336v35Zm-76 108h440q13 0 22.5-9t9.5-23v-436q0-13-9.5-22.5T700-686H260q-13 0-22.5 9.5T228-654v436q0 14 9.5 23t22.5 9Zm220-250Z"
      /></svg
    ><svg viewBox="0 0 18 18" id="search" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="currentColor"
        stroke-width="1.5"
        fill="none"
        fill-rule="evenodd"
        stroke-dasharray="0,0"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M8.103 15.206A7.103 7.103 0 1 0 8.103 1a7.103 7.103 0 0 0 0 14.206ZM12.905 13.413 16.492 17" />
      </g></svg
    ><svg viewBox="0 -960 960 960" fill="currentColor" id="send" xmlns="http://www.w3.org/2000/svg">
      <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" /></svg
    ><svg viewBox="0 0 24 24" fill="none" id="share" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.553 10.114a2.667 2.667 0 1 1-3.772 3.772 2.667 2.667 0 0 1 3.772-3.772M19.219 4.781a2.667 2.667 0 1 1-3.772 3.772 2.667 2.667 0 0 1 3.772-3.772M19.219 15.447a2.667 2.667 0 1 1-3.772 3.772 2.667 2.667 0 0 1 3.772-3.772M9.04 10.81l5.92-2.96M9.04 13.19l5.92 2.96"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      /></svg
    ><svg viewBox="0 0 25 25" id="shopping-cart" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M5 22.5C5 23.878 6.122 25 7.5 25s2.5-1.122 2.5-2.5S8.878 20 7.5 20A2.503 2.503 0 0 0 5 22.5Zm3.333 0c0 .46-.374.833-.833.833a.835.835 0 0 1-.833-.833c0-.46.374-.833.833-.833.46 0 .833.374.833.833ZM21 22.5c0-1.378-1.122-2.5-2.5-2.5a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5Zm-3.333 0c0-.46.374-.833.833-.833.46 0 .833.374.833.833 0 .46-.374.833-.833.833a.835.835 0 0 1-.833-.833ZM2.694 1.714l1.493 13.984C4.327 17.01 5.442 18 6.78 18h13.543c1.222 0 2.294-.855 2.55-2.033l2.088-9.608a1.687 1.687 0 0 0-.348-1.436 1.742 1.742 0 0 0-1.353-.637H4.717L4.34.768A.865.865 0 0 0 3.476 0H.87C.39 0 0 .384 0 .857s.39.857.87.857h1.824ZM23.26 6l-2.087 9.608a.873.873 0 0 1-.85.678H6.78a.863.863 0 0 1-.865-.767L4.9 6H23.26Z"
        />
      </g></svg
    ><svg
      viewBox="0 0 52 52"
      style="enable-background: new 0 0 52 52"
      xml:space="preserve"
      id="success"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 0C11.664 0 0 11.663 0 26s11.664 26 26 26 26-11.663 26-26S40.336 0 26 0zm0 50C12.767 50 2 39.233 2 26S12.767 2 26 2s24 10.767 24 24-10.767 24-24 24z"
      />
      <path
        d="m38.252 15.336-15.369 17.29-9.259-7.407a1 1 0 0 0-1.249 1.562l10 8a.999.999 0 0 0 1.373-.117l16-18a1 1 0 1 0-1.496-1.328z"
      /></svg
    ><svg viewBox="0 0 16 13" fill="none" id="tag" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.72 3.64a.999.999 0 0 0 .307-.732.972.972 0 0 0-.306-.724.972.972 0 0 0-.724-.306.999.999 0 0 0-.732.306.985.985 0 0 0-.298.724c0 .284.1.528.298.732.204.198.448.297.732.297s.526-.099.724-.297Zm8.89 3.9c0 .285-.1.526-.298.725l-3.95 3.957a1.03 1.03 0 0 1-.732.298.985.985 0 0 1-.724-.298l-5.751-5.76C.951 6.266.777 5.995.632 5.65a2.524 2.524 0 0 1-.209-.94V1.362A.99.99 0 0 1 .729.64a.99.99 0 0 1 .724-.305h3.346c.284 0 .598.072.94.217.344.14.618.31.821.515l5.752 5.743c.198.209.297.453.297.732Zm3.088 0c0 .285-.1.526-.298.725l-3.949 3.957a1.03 1.03 0 0 1-.732.298.9.9 0 0 1-.475-.113 2.282 2.282 0 0 1-.426-.362l3.78-3.78a.985.985 0 0 0 .298-.724c0-.28-.099-.523-.297-.732L7.847 1.066a2.445 2.445 0 0 0-.82-.515C6.684.406 6.37.334 6.086.334h1.802c.284 0 .598.072.94.217.344.14.617.31.821.515l5.752 5.743c.198.209.297.453.297.732Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 60 59" id="tent" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 57h2v2h-2v-2ZM35.233 42.753l1.731-.932 6.884 12.783-1.732.933-6.883-12.784ZM12.524 27.71 14 26.296l-5.956-5.707v-3.171l4.48 4.292L14 20.296l-6.262-6-.007.008a1.048 1.048 0 0 0-1.47-.006l-6.261 6 1.476 1.413 4.48-4.293v3.171L.002 26.296l1.476 1.414 4.48-4.293v3.172L0 32.295l1.476 1.414 4.48-4.292v3.171L0 38.295l1.476 1.414 4.48-4.293V59h2.087V35.416l4.48 4.293L14 38.295l-5.956-5.707v-3.171l4.48 4.292L14 32.295 8.044 26.59v-3.172l4.48 4.293Z"
        fill="currentColor"
      />
      <path
        d="M58.606 32.255 60 30.869l-5.627-5.592v-3.108l4.233 4.206L60 24.99l-5.916-5.88-.007.007a.965.965 0 0 0-1.389-.006l-5.916 5.88 1.394 1.385L52.4 22.17v3.108l-5.627 5.592 1.394 1.386 4.233-4.207v3.109l-5.627 5.592 1.394 1.386 4.233-4.207v9.393l-2.07-4.338a.986.986 0 0 0-.888-.561H36.623v-7.434l4.233 4.207 1.395-1.386-5.628-5.592v-3.109l4.233 4.207 1.395-1.385-5.628-5.593V19.23l4.233 4.207 1.395-1.386-5.628-5.592v-3.109l4.233 4.207 1.395-1.386-5.917-5.879-.007.007a.965.965 0 0 0-1.388-.006l-5.916 5.88 1.394 1.385 4.234-4.208v3.109l-5.627 5.592 1.394 1.386 4.233-4.207v3.108l-5.627 5.593 1.394 1.385 4.233-4.207v3.109l-5.627 5.592 1.394 1.386 4.233-4.207v7.434H20.846a.975.975 0 0 0-.726.329.846.846 0 0 0-.146.196l-9.861 18.618a.977.977 0 0 0 .416 1.323.99.99 0 0 0 .456.112h29.583v-1.96h-9.27L22.48 40.382h26.339l7.94 16.658H46.485V59h11.833a.983.983 0 0 0 .887-1.4l-4.831-10.142v-13.53l4.233 4.207L60 36.748l-5.627-5.593v-3.108l4.233 4.207ZM19.86 57.04h-7.242l7.242-13.673V57.04Zm9.214 0h-7.242V43.367l7.242 13.673ZM18.5 0a1 1 0 0 0-.707 1.707 5.827 5.827 0 0 1 1.882 3.571 3.076 3.076 0 0 1-3.4 3.4l.003-.003a5.827 5.827 0 0 1-3.571-1.882A1 1 0 0 0 11 7.5 7.5 7.5 0 1 0 18.5 0Zm4.953 9.897a5.5 5.5 0 0 1-9.899.003 6.59 6.59 0 0 0 2.574.765 5.15 5.15 0 0 0 4.08-1.458A5.564 5.564 0 0 0 20.9 2.55a5.5 5.5 0 0 1 2.553 7.347Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 52 52" id="twitter" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 51c13.807 0 25-11.193 25-25S39.807 1 26 1 1 12.193 1 26s11.193 25 25 25Z"
        fill="inherit"
        fill-rule="evenodd"
      />
      <path d="M14.736 16.934h24.176v19.34H14.736v-19.34Z" fill="none" />
      <g clip-path="url(#mask_1)" transform="translate(14.736 16.934)">
        <path
          d="M7.603 19.34c9.123 0 14.113-7.44 14.113-13.891 0-.212-.005-.422-.015-.631.969-.69 1.81-1.55 2.475-2.528-.889.388-1.845.65-2.849.768a4.914 4.914 0 0 0 2.181-2.7c-.958.559-2.02.966-3.149 1.185A4.992 4.992 0 0 0 16.739 0c-2.74 0-4.961 2.187-4.961 4.882 0 .383.043.756.128 1.113A14.157 14.157 0 0 1 1.683.895a4.808 4.808 0 0 0-.672 2.453 4.86 4.86 0 0 0 2.207 4.064 4.983 4.983 0 0 1-2.246-.61l-.001.062c0 2.365 1.71 4.339 3.979 4.786a5.047 5.047 0 0 1-2.24.084c.631 1.94 2.462 3.351 4.634 3.391a10.053 10.053 0 0 1-6.16 2.09c-.401 0-.796-.022-1.184-.067a14.204 14.204 0 0 0 7.603 2.193"
          fill="#FFF"
          fill-rule="evenodd"
        />
      </g></svg
    ><svg viewBox="0 0 35 35" id="upload" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path
          d="m12.043 9.647 3.676-3.635v19.227c0 .973.798 1.761 1.781 1.761.983 0 1.78-.788 1.78-1.76V6.011l3.676 3.635c.45.447 1.107.622 1.723.46a1.77 1.77 0 0 0 1.26-1.248 1.748 1.748 0 0 0-.464-1.703L18.759.516a1.79 1.79 0 0 0-2.518 0l-6.716 6.64A1.748 1.748 0 0 0 9.06 8.86a1.77 1.77 0 0 0 1.261 1.247 1.795 1.795 0 0 0 1.723-.46Z"
        />
        <path
          d="M33.19 15c-1 0-1.81.84-1.81 1.875V31.25H3.62V16.875C3.62 15.839 2.81 15 1.81 15S0 15.84 0 16.875v15C0 33.601 1.35 35 3.017 35h28.966C33.649 35 35 33.6 35 31.875v-15C35 15.839 34.19 15 33.19 15Z"
        />
      </g></svg
    ><svg viewBox="0 0 76 48" id="video-camera" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M75.211 4.281a1.608 1.608 0 0 0-1.593-.029L56.18 13.795V8.07C56.175 3.615 52.573.005 48.129 0H8.051C3.607.005.005 3.615 0 8.07v31.86c.005 4.455 3.607 8.065 8.05 8.07H48.13c4.444-.005 8.046-3.615 8.05-8.07V34.31l17.44 9.543a1.607 1.607 0 0 0 1.592-.028c.49-.292.789-.82.789-1.389V5.67c0-.57-.3-1.097-.789-1.388Zm-22.253 35.65a4.84 4.84 0 0 1-4.83 4.842H8.05a4.841 4.841 0 0 1-4.83-4.842V8.07a4.842 4.842 0 0 1 4.83-4.841h40.078a4.842 4.842 0 0 1 4.83 4.841v31.862Zm19.822-.215-16.6-9.085V17.473l16.6-9.084v31.327Z"
        fill="currentColor"
      /></svg
    ><svg viewBox="0 0 24 24" id="visibility" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      /></svg
    ><svg viewBox="0 0 24 24" id="visibility-off" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" />
      <path
        d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46A11.804 11.804 0 0 0 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
      /></svg
    ><svg viewBox="0 -960 960 960" fill="currentColor" id="warn-circle" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M479.82-292q12.68 0 21.43-8.57 8.75-8.58 8.75-21.25 0-12.68-8.57-21.43-8.58-8.75-21.25-8.75-12.68 0-21.43 8.57-8.75 8.58-8.75 21.25 0 12.68 8.57 21.43 8.58 8.75 21.25 8.75ZM454-434h54v-246h-54v246Zm26.17 334q-78.81 0-148.21-29.91T211.23-211.1q-51.34-51.28-81.28-120.59Q100-401.01 100-479.83q0-79.07 29.97-148.69t81.35-121.13q51.38-51.5 120.59-80.92Q401.13-860 479.83-860q79.06 0 148.67 29.39 69.62 29.39 121.13 80.85 51.52 51.46 80.94 121.02Q860-559.18 860-480.09t-29.39 148.15q-29.39 69.06-80.84 120.49-51.44 51.44-120.98 81.45-69.55 30-148.62 30Zm-.17-54q136.51 0 231.26-94.74Q806-343.49 806-480t-94.74-231.26Q616.51-806 480-806t-231.26 94.74Q154-616.51 154-480t94.74 231.26Q343.49-154 480-154Zm0-326Z"
      />
    </svg>
  </defs>
</svg>
