import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormControlName, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IDestroyable } from '../lifecycle';
import { BaseControl } from './base-control.class';
import { FormStateDispatcher } from './form-state.dispatcher';

export abstract class BaseControlComponent<T, R = T> extends BaseControl<T> implements Validator, IDestroyable {
  readonly destroyed$: Observable<void>;
  abstract readonly control: UntypedFormControl;
  protected onValidatorChange?: () => void;
  protected onChanged?: (value?: T | R | null) => void;
  protected onTouched?: () => void;

  protected readonly modelToViewFormatter?: (value: T | null) => T | R | null = identity;
  protected readonly viewToModelParser?: (value: R | null) => T | R | null = identity;

  get value(): T {
    return this.control.value;
  }

  public get required() {
    if (!this.control?.validator) {
      return false;
    }
    const validation = this.control.validator(new UntypedFormControl());
    return validation?.required === true;
  }

  writeValue(value: T | null): void {
    this.control.setValue(this.modelToViewFormatter?.(value), {
      emitEvent: false
    });
  }

  registerOnChange(fn: (value: T) => void) {
    super.registerOnChange(fn);

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.onChanged?.(this.viewToModelParser?.(value));
      this.onTouched?.();
    });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      return this.control.disable({ emitEvent: false });
    }
    this.control.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const { valid, errors } = control;

    return valid ? null : errors;
  }

  initFormControlValidations(
    ctrl: FormControlName,
    formState: FormStateDispatcher | null,
    changeDetector: ChangeDetectorRef
  ): void {
    if (ctrl?.control?.validator) {
      this.control.setValidators(ctrl.control.validator);
    }
    if (ctrl?.control?.asyncValidator) {
      this.control.setAsyncValidators(ctrl.control.asyncValidator);
    }
    this.onValidatorChange?.();

    ctrl?.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = { ...this.control?.errors, ...ctrl?.control.errors };
      this.control.setErrors(Object.keys(errors).length ? errors : null);
      changeDetector.markForCheck();
    });

    formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      changeDetector.markForCheck();
    });

    formState?.onReset.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsPristine();
      this.control.markAsUntouched();
      changeDetector.markForCheck();
    });
  }
}
