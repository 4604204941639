<div class="px-4 px-md-5 py-4 group-timeline">
  <div *ngFor="let user of members; trackBy: trackByFn" class="mb-4 timeline-entity">
    <div class="header row">
      <div class="user col-12">
        <!--          TODO move to common component?-->
        <ct-user-avatar
          class="user__avatar"
          [src]="getCroppedThumbPublicUrl(user?.photo?.publicUrl)"
          width="32px"
          height="32px"
        ></ct-user-avatar>
        <div class="user__link" [routerLink]="['/user-profile']" [queryParams]="{ userId: user.userId }">
          <p class="user__name">{{ user.firstName }} {{ user.lastName }}</p>
          <p class="user__name user__name--light">{{ user.username }}</p>
        </div>
        <div class="ml-auto d-flex flex-column">
          <span
            class="user__remove mr-2"
            *ngIf="isAdmin && user.userId !== (loggedInUser$ | async)?.userId"
            (click)="removeMember(user)"
            >{{ 'COMMON.DELETE' | translate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showLoadButton && members?.length"
  class="load-more"
  [ngClass]="{ disabled: loading }"
  (click)="onScroll()"
  disabled="loading"
>
  Load More
</div>
