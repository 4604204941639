import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '@ct/components';
import { BlogPost, DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { trackById } from '@ct/shared';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { MY_ACCOUNT_POSTS_FEATURE_LIMIT } from '../../../../constants';
import { MyAccountBlogPostApiService } from '../../../my-account-writings/services';

interface StoryQueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-select-story-dialog',
  templateUrl: './select-story-dialog.component.html',
  styleUrls: ['./select-story-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectStoryDialogComponent {
  static dialogConfig: MatDialogConfig = {
    minWidth: '700px'
  };
  public readonly destroyed$: Observable<void>;
  public readonly limit = MY_ACCOUNT_POSTS_FEATURE_LIMIT;
  public loading = false;
  public showLoadButton = true;
  public blogPosts: BlogPost[] = [];

  public queryParams: StoryQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public readonly trackByFn = trackById;

  constructor(
    private dialogRef: MatDialogRef<SelectStoryDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountBlogPostApiService: MyAccountBlogPostApiService,
    private spinnerService: SpinnerService
  ) {
    this.loading = true;
    this.loadStories(this.queryParams).subscribe((blogPosts) => {
      this.blogPosts.push(...blogPosts);
      if (blogPosts.length === 0 || blogPosts.length < this.limit) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  onSelect(event: MouseEvent, post: BlogPost) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    this.dialogRef.close(post);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadStories(this.queryParams).subscribe((blogPosts) => {
      this.blogPosts.push(...blogPosts);
      if (blogPosts.length === 0 || blogPosts.length < this.limit) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  loadStories({ offset }: StoryQueryParams) {
    this.spinnerService.show();
    this.loading = true;
    return this.myAccountBlogPostApiService.getAllByAuthorId({ limit: this.limit, offset }).pipe(
      finalize(() => {
        this.loading = false;
        this.spinnerService.hide();
      })
    );
  }
}
