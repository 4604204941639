import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { InformationDialogComponent } from '@ct/components/dialog/components';
import { DialogConfig } from '@ct/components/dialog/interfaces';
import { DialogService } from '@ct/components/dialog/services';
import { SpinnerService } from '@ct/components/spinner/services';
import { DestroyableFeature, Features } from '@ct/core/features';
import { trackById } from '@ct/core/helpers';
import { Group, UserProfile } from '@ct/core/interfaces';
import { getCroppedThumbPublicUrl } from '@ct/shared/helpers';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { GroupApiService } from '../services';

@Component({
  selector: 'ct-manage-group-dialog',
  templateUrl: './manage-group-dialog.component.html',
  styleUrls: ['./manage-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class ManageGroupDialogComponent {
  public readonly destroyed$: Observable<void>;

  public isLoading = false;

  protected readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;
  protected trackByFn = trackById;

  protected group: Group;
  protected users: UserProfile[];
  protected readonly groupAdminIds: Set<string>;

  get groupId() {
    return this.data.group?.id;
  }

  get currentUserId(): string {
    return this.data.currentUserId;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ManageGroupDialogComponent>,
    private groupApiService: GroupApiService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.group = { ...this.data.group };
    this.users = [...this.data.users];
    this.groupAdminIds = new Set(this.group.adminIds);
  }

  protected toggleAdmin(user: UserProfile) {
    this.isLoading = true;
    this.spinnerService.show();
    this.changeDetectorRef.markForCheck();

    if (this.groupAdminIds.has(user.userId)) {
      const adminIds = [...this.groupAdminIds].filter((adminId) => adminId !== user.userId);

      if (!adminIds.length) {
        this.isLoading = false;
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
        this.dialogService.open(InformationDialogComponent, {
          data: {
            title: 'Warning',
            message: 'Group should have at least one admin',
            canConfirm: false
          },
          width: '450px'
        } as MatDialogConfig<DialogConfig>);
        return;
      }
      this.groupApiService
        .update(this.groupId, { adminIds })
        .pipe(
          takeUntil(this.destroyed$),
          finalize(() => {
            this.isLoading = false;
            this.spinnerService.hide();
            this.changeDetectorRef.markForCheck();
            if (user.userId === this.currentUserId) {
              this.dialogRef.close();
            }
          })
        )
        .subscribe(() => {
          this.groupAdminIds.delete(user.userId);
        });
    } else {
      const adminIds = [...this.groupAdminIds, user.userId];
      this.groupApiService
        .update(this.groupId, { adminIds })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.spinnerService.hide();
            this.changeDetectorRef.markForCheck();
          })
        )
        .subscribe(() => {
          this.groupAdminIds.add(user.userId);
        });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  isAdmin(user: UserProfile) {
    return this.groupAdminIds.has(user.userId);
  }
}
