import { GroupType } from '../enums';
import { UploadedImage } from './uploaded-image.interface';

export interface Group {
  readonly id?: string;
  readonly adminIds?: string[];
  readonly visibility?: GroupType;
  readonly title: string;
  readonly description?: string;
  readonly postingEnabled?: boolean;
  readonly createdOn?: Date;
  readonly imageId?: string | null;
  readonly image?: UploadedImage;
}

export interface GroupWithStories extends Group {
  members: number;
  sharedBlogPosts: string[];
}

export class GroupInvitation {
  constructor(public userId: string) {}
}
