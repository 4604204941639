import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery, AuthService, UserProfileApiService } from '@ct/auth';
import { DestroyableFeature, Features, Group, GroupFilterType, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { GroupApiService, trackById } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { MY_ACCOUNT_GROUPS_FEATURE_LIMIT } from './../../../../constants/';

interface PhotoQueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-my-account-groups-pending',
  templateUrl: './my-account-groups-pending.component.html',
  styleUrls: ['./my-account-groups-pending.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature()])
export class MyAccountGroupsPendingComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_PHOTOS'
  };
  public readonly limit = MY_ACCOUNT_GROUPS_FEATURE_LIMIT;

  public groups: Group[] = [];
  public loading = false;
  public showLoadButton = true;
  public multiselect = false;

  public queryParams: PhotoQueryParams = {
    offset: DEFAULT_OFFSET
  };

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public trackByFn = trackById;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountGroupApiService: GroupApiService,
    private userProfileApiService: UserProfileApiService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ groups }) => {
      this.groups = groups;
    });
    this.route.queryParams.pipe(take(1)).subscribe(({ offset }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset
      };
      this.showLoadButton = !(this.groups.length === 0 || this.groups.length < this.limit);
    });
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadGroups(this.queryParams).subscribe((groups) => this.refreshGroups(groups));
  }

  loadGroups({ offset }: PhotoQueryParams) {
    this.loading = true;
    return this.myAccountGroupApiService
      .findAllMemberGroups({ range: { limit: this.limit, offset }, filterType: GroupFilterType.PendingInvitations })
      .pipe(
        tap(() => (this.loading = false)),
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        })
      );
  }

  refreshGroups(groups: Group[]) {
    if (this.queryParams.offset === 0) {
      this.groups = [...groups];
    } else {
      this.groups = [...this.groups, ...groups];
    }
    this.showLoadButton = !(groups.length === 0 || groups.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  getLink(group: Group): string {
    return `/my-account/groups/${group.id}/timeline`;
  }

  onGroupJoin(group: Group) {
    this.myAccountGroupApiService
      .acceptInvitation(group.id as string)
      .pipe(
        switchMap(() => this.loggedInUser$.pipe(take(1))),
        switchMap((userProfile) => this.userProfileApiService.getByUserId(userProfile?.userId as string)),
        switchMap((userProfile) => this.authService.updateUserProfile(userProfile))
      )
      .subscribe(() => {
        this.groups = this.groups.filter(({ id }) => id !== group.id);
        this.changeDetectorRef.detectChanges();
      });
  }

  onCancelRequest(group: Group) {
    this.myAccountGroupApiService
      .cancelRequest(group.id as string)
      .pipe(
        switchMap(() => this.loggedInUser$.pipe(take(1))),
        switchMap((userProfile) => this.userProfileApiService.getByUserId(userProfile?.userId as string)),
        switchMap((userProfile) => this.authService.updateUserProfile(userProfile))
      )
      .subscribe(() => {
        this.groups = this.groups.filter(({ id }) => id !== group.id);
        this.changeDetectorRef.detectChanges();
      });
  }
}
