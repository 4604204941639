<ct-user-avatar
  class="channel__avatar"
  [src]="getCroppedThumbPublicUrl(channel?.avatarImage?.publicUrl)"
  width="128px"
  height="128px"
  [round]="false"
></ct-user-avatar>

<ng-container *ngIf="clickable; else simpleNamesTpl">
  <div class="channel__link" (click)="channelClick.emit()">
    <ng-container *ngTemplateOutlet="namesTpl"></ng-container>
  </div>
</ng-container>
<div class="ml-auto">
  <ng-content></ng-content>
</div>

<ng-template #simpleNamesTpl>
  <div>
    <ng-container *ngTemplateOutlet="namesTpl"></ng-container>
  </div>
</ng-template>

<ng-template #namesTpl>
  <p class="channel__name">{{ channel.title }}</p>
  <p class="channel__name channel__name--light">{{ channel.author?.username }}</p>
  <p class="channel__description">{{ channel.description }}</p>
</ng-template>
