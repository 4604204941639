import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IDestroyable } from '../lifecycle';

const submitSubject = Symbol('submitSubject');
const resetSubject = Symbol('resetSubject');
const deleteSubject = Symbol('deleteSubject');
const cancelSubject = Symbol('cancelSubject');

class SubmitFormStateDispatcher<T> {
  readonly [submitSubject] = new Subject<T>();
  readonly listen = this[submitSubject].asObservable();

  notify(state?: T) {
    this[submitSubject].next(state);
  }
}

class ResetFormStateDispatcher<T> {
  readonly [resetSubject] = new Subject<T>();
  readonly listen = this[resetSubject].asObservable();

  notify(state?: T) {
    this[resetSubject].next(state);
  }
}

class CancelFormStateDispatcher {
  readonly [cancelSubject] = new Subject<void>();
  readonly listen = this[cancelSubject].asObservable();

  notify() {
    this[cancelSubject].next();
  }
}

class DeleteFormStateDispatcher {
  readonly [deleteSubject] = new Subject<void>();
  readonly listen = this[deleteSubject].asObservable();

  notify() {
    this[deleteSubject].next();
  }
}

@Injectable({ providedIn: 'root' })
export class FormStateDispatcher implements IDestroyable {
  readonly destroyed$: Observable<void>;
  readonly onSubmit = new SubmitFormStateDispatcher();
  readonly onReset = new ResetFormStateDispatcher();
  readonly onCancel = new CancelFormStateDispatcher();
  readonly onDelete = new DeleteFormStateDispatcher();
}
