<div class="channel">
  <div class="channel__banner-container">
    <img
      class="channel__img"
      [class.channel__img--default]="!channel?.bannerImage?.publicUrl"
      [class.channel__img--small]="channel?.bannerImageSize !== 'large'"
      [src]="channel?.bannerImage?.publicUrl ?? 'assets/logos/main-logo-light.png'"
      loading="lazy"
      alt="channel banner"
    />
    <ct-user-avatar class="channel__user-photo" [src]="channel?.avatarImage?.publicUrl"></ct-user-avatar>
    <ct-button *ngIf="canJoin" class="channel__join" variant="flat" color="primary" (click)="joinChannel()"
      >Join Channel</ct-button
    >
    <ct-button *ngIf="canLeave" class="channel__join" variant="flat" color="warn" (click)="leaveChannel()"
      >Leave Channel</ct-button
    >
    <ct-button *ngIf="canEdit" class="channel__join" variant="flat" color="accent" (click)="onEdit()">Edit</ct-button>
  </div>
  <h1 class="channel__name">{{ channel?.title }}</h1>
  <p class="channel__description">
    {{ channel?.description }}
  </p>

  <ng-container>
    <ct-tab-header
      class="d-none d-md-flex"
      style="margin-bottom: -1px"
      [tabs]="tabs"
      (tabChange)="onFilterChanged($event)"
    >
    </ct-tab-header>
    <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)"> </ct-tabs-mobile>
    <div
      class="row px-4 px-md-5 mb-4 pt-4 with-background main-content"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <div class="with-background" *ngIf="queryParams.filter === channelStatus.Activity">
        <ng-container *ngIf="selectedFiles && selectedVideoName">
          <h3>Latest Video:</h3>
          <h4 class="col-12 mt-4" *ngIf="files?.length && files[0].transcodingJobStatus !== transcodingState.SUCCEEDED">
            Video is being processed. Please, refresh page in a minute to check status.
          </h4>
          <ct-my-video-player
            [class.video-list__entity--disabled]="files[0].transcodingJobStatus !== transcodingState.SUCCEEDED"
            *ngIf="selectedFiles && selectedVideoName"
            [hlsUrl]="m3u8 && decodeUrl(m3u8)"
            [dashUrl]="mpd && decodeUrl(mpd)"
          ></ct-my-video-player>
          <ng-container *ngIf="selectedFiles && selectedVideoName; else noEntities">
            <ng-container *ngTemplateOutlet="elementFooter; context: { entity: files[0] }"></ng-container>
          </ng-container>
        </ng-container>
        <ct-channel-timeline></ct-channel-timeline>
      </div>

      <div *ngIf="queryParams.filter === channelStatus.Stories">
        <ng-container *ngIf="posts?.length; else noEntities">
          <div *ngFor="let post of posts; trackBy: trackByFn">
            <ct-blog-post-card
              class="mb-4"
              [post]="post"
              [showLink]="true"
              [showStatus]="false"
              imgHeight="220px"
              imgWidth="100%"
              [link]="getLink(post)"
            ></ct-blog-post-card>
          </div>
          <div
            *ngIf="showLoadButton"
            class="load-more"
            [ngClass]="{ disabled: loading }"
            (click)="onScroll()"
            disabled="loading"
          >
            Load More
          </div>
        </ng-container>
      </div>
      <div *ngIf="queryParams.filter === channelStatus.Media" class="col-12">
        <div class="video-list" *ngIf="files?.length; else noEntities">
          <ng-container *ngFor="let video of files">
            <div class="mb-4 timeline-entity col-12">
              <ct-video-item-preview [video]="video"> </ct-video-item-preview>

              <ng-container *ngTemplateOutlet="elementFooter; context: { entity: video }"></ng-container>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="showLoadButton"
          class="load-more"
          [ngClass]="{ disabled: loading }"
          (click)="onScroll()"
          disabled="loading"
        >
          Load More
        </div>
      </div>
      <div *ngIf="queryParams.filter === channelStatus.Events" class="col-12">
        <div class="video-list" *ngIf="calendarEvents?.length; else noEntities">
          <ng-container *ngFor="let event of calendarEvents">
            <div class="event mb-4 timeline-entity col-12">
              <h2 class="channel__event-title">{{ event.summary }}</h2>
              <p *ngIf="event.description" [innerHTML]="event.description | safe : 'html'"></p>
              <h3 *ngIf="event.location" class="channel__event-location">
                <ct-icon icon="location-on"></ct-icon>{{ event.location }}
              </h3>
              <p class="channel__date">
                {{ event.start?.dateTime ?? event.start?.date | date : 'fullDate':event.start.timeZone }}
                {{ event.start?.dateTime | date : 'shortTime':event.start.timeZone }} -
                {{ event.end?.dateTime ?? event.end?.date | date : 'fullDate':event.end.timeZone }}
                {{ event.end?.dateTime | date : 'shortTime':event.end.timeZone }}
              </p>
              <p *ngIf="event.start.timeZone">(event is displayed in {{ event.start.timeZone }} timezone)</p>
              <ct-link [link]="event.htmlLink" queryParamsHandling="preserve">
                <ct-button variant="flat" color="primary" class="mb-4">See event in Google Calendar</ct-button>
              </ct-link>
              <ct-link
                *ngIf="event.conferenceData"
                [link]="event.conferenceData.entryPoints[0].uri"
                queryParamsHandling="preserve"
              >
                <ct-button variant="flat" color="primary" class="mb-4"
                  ><ct-image
                    [src]="event.conferenceData.conferenceSolution.iconUri"
                    class="conference-solution__icon"
                  ></ct-image>
                  Open&nbsp;{{ event.conferenceData.conferenceSolution.name }}</ct-button
                >
              </ct-link>
              <p *ngIf="event.attachments?.length">
                <span>Attachments: </span>
                <ct-link
                  *ngFor="let attachment of event.attachments"
                  [link]="attachment.fileUrl"
                  queryParamsHandling="preserve"
                >
                  <ct-image [src]="attachment.iconLink" class="mb-4"></ct-image>
                </ct-link>
              </p>
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="showLoadButton"
          class="load-more"
          [ngClass]="{ disabled: loading }"
          (click)="onScroll()"
          disabled="loading"
        >
          Load More
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #elementFooter let-entity="entity">
    <div class="row">
      <div class="col-12 extra-features">
        <div class="extra-features__action" (click)="toggleComments(entity)">
          <ct-icon icon="chat"></ct-icon>{{ entity?.metadata?.threads?.length }}
        </div>
        <ct-like
          class="extra-features__action"
          [likes]="entity?.metadata?.likes"
          [user]="loggedInUser$ | async"
          (like)="onLike(entity)"
          (dislike)="onDislike(entity)"
        ></ct-like>
      </div>
    </div>
    <ct-entity-comments
      *ngIf="entity?.fileName === selectedVideoName"
      class="mt-4"
      [entityId]="entity?.metadataId"
      [entityType]="entity?.type"
      [disableComments]="false"
      [fetchOnInit]="false"
      [threads]="entity?.metadata?.threads"
      [user]="loggedInUser$ | async"
    ></ct-entity-comments>
  </ng-template>

  <div *ngIf="canJoin" class="placeholder">
    <ct-button variant="flat" color="primary" (click)="joinChannel()">{{ 'Join Channel' | translate }}</ct-button>
  </div>
</div>

<ng-template #noEntities>
  <h4>Unfortunately there is nothing uploaded yet.</h4>
</ng-template>
