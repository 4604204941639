import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserAvatarModule } from '@ct/components';
import { ButtonModule } from '@ct/components/button';
import { DialogModule } from '@ct/components/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { MediaSharedModule } from '../../media-shared';
import { ManageGroupDialogComponent } from './manage-group-dialog.component';

@NgModule({
  declarations: [ManageGroupDialogComponent],
  imports: [CommonModule, DialogModule, TranslateModule, ButtonModule, MediaSharedModule, UserAvatarModule],
  exports: [ManageGroupDialogComponent]
})
export class ManageGroupDialogModule {}
