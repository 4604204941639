<div class="px-4 px-md-5 py-4 groups-list">
  <ct-group-card
    *ngFor="let group of groups; trackBy: trackByFn"
    [group]="group"
    [user]="loggedInUser$ | async"
    imgHeight="134px"
    imgWidth="100%"
    class="mb-4"
    (joinGroup)="onGroupJoin($event)"
    (cancelRequest)="onCancelRequest($event)"
    [link]="getLink(group)"
  ></ct-group-card>
</div>
<div
  *ngIf="showLoadButton && groups?.length"
  class="load-more"
  [ngClass]="{ disabled: loading }"
  (click)="onScroll()"
  disabled="loading"
>
  Load More
</div>
