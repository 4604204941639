<ct-dialog-content titleKey="Select Trip To Share" [closeIcon]="true" headerIcon="add-circle">
  <div
    class="row mb-4 trips-list"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    <ct-trip-card
      class="col-12 col-md-4 mb-3 cursor--pointer"
      *ngFor="let trip of trips; trackBy: trackByFn"
      [trip]="trip"
      (click)="onSelect($event, trip)"
    ></ct-trip-card>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</ct-dialog-content>
