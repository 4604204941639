<router-outlet></router-outlet>

<!-- Floating Action Button to open the chat -->
<ct-button color="primary" class="chat-fab" (click)="toggleChat()">
  <ct-icon icon="chat"></ct-icon>
</ct-button>

<!-- Chat panel (visible if chat is open) -->
<div class="chat-container" *ngIf="chatService.isChatOpen">
  <ct-my-account-chat></ct-my-account-chat>
</div>
