import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DeletionDialogComponent, DialogService, InformationDialogComponent } from '@ct/components';
import { DestroyableFeature, Features, Group, GroupFilterType, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { getCroppedThumbPublicUrl, GroupApiService, trackById } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { MY_ACCOUNT_GROUPS_FEATURE_LIMIT } from './../../../../constants/';

interface SimpleQueryParams {
  offset: number;
}

const DEFAULT_OFFSET = 0;

@Component({
  selector: 'ct-my-account-group-members-invitation',
  templateUrl: './my-account-group-members-invitation.component.html',
  styleUrls: ['./my-account-group-members-invitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature(), TitleFeature()])
export class MyAccountGroupMembersInvitationComponent implements OnInit {
  public readonly destroyed$: Observable<void>;
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT_PHOTOS'
  };
  public readonly limit = MY_ACCOUNT_GROUPS_FEATURE_LIMIT;
  public readonly getCroppedThumbPublicUrl = getCroppedThumbPublicUrl;

  public members: UserProfile[] = [];
  public group: Group;
  public loading = false;
  public showLoadButton = true;
  public multiselect = false;

  inviteUserControl = new UntypedFormControl(null);

  public queryParams: SimpleQueryParams = {
    offset: DEFAULT_OFFSET
  };

  get isAdmin() {
    return (
      this.group && this.authQuery.profile && this.group.adminIds?.includes(this.authQuery.profile.userId as string)
    );
  }

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  public trackByFn = trackById;

  public optionsFn = (match: string) => this.getPotentialMembers(match);

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountGroupApiService: GroupApiService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {}

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(({ members, group }) => {
      this.members = members;
      this.group = group;

      this.getPotentialMembers().subscribe();
    });
    this.route.queryParams.pipe(take(1)).subscribe(({ offset }) => {
      this.queryParams = {
        ...this.queryParams,
        offset: +offset || this.queryParams.offset
      };
      this.showLoadButton = !(this.members.length === 0 || this.members.length < this.limit);
    });

    this.inviteUserControl.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((user: UserProfile) => {
          return this.dialogService
            .open(InformationDialogComponent, {
              data: {
                title: 'Invite User',
                message: `Are you sure you want to invite this user to your group? He won't show up in the members list until he accepts your invitation`
              },
              width: '450px'
            })
            .afterClosed()
            .pipe(
              filter(Boolean),
              map(() => user)
            );
        }),
        switchMap((user: UserProfile) => {
          // TODO use Store
          return this.myAccountGroupApiService.sendGroupInvitation(this.group.id as string, user.userId);
        })
      )
      .subscribe(() => {
        this.loading = true;
        this.queryParams.offset = DEFAULT_OFFSET;
        this.loadMembers(this.queryParams).subscribe((members) => this.refreshMembers(members));
      });
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadMembers(this.queryParams).subscribe((members) => this.refreshMembers(members));
  }

  loadMembers({ offset }: SimpleQueryParams) {
    this.loading = true;
    return this.myAccountGroupApiService
      .findAllGroupMembers(this.group.id as string, {
        range: { limit: this.limit, offset },
        filterType: GroupFilterType.PendingInvitations
      })
      .pipe(finalize(() => (this.loading = false)));
  }

  refreshMembers(members: UserProfile[]) {
    if (this.queryParams.offset === DEFAULT_OFFSET) {
      this.members = [...members];
    } else {
      this.members = [...this.members, ...members];
    }
    this.showLoadButton = !(members.length === 0 || members.length < this.limit);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  removeMember({ username, userId }: UserProfile) {
    this.dialogService
      .open(DeletionDialogComponent, {
        data: {
          title: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.CONFIRM_CANCEL.TITLE'),
          message: this.translateService.instant('MY_ACCOUNT.GROUPS_FEATURE.CONFIRM_CANCEL.MESSAGE', {
            username
          })
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.myAccountGroupApiService.removeUserFromGroup(this.group.id as string, userId))
      )
      .subscribe(() => {
        this.members = this.members.filter((member) => member.userId !== userId);
        this.changeDetectorRef.detectChanges();
      });
  }

  private getPotentialMembers(match?: string) {
    return this.group.id
      ? this.myAccountGroupApiService
          .getPossibleMembers({ groupId: this.group.id as string, match })
          .pipe(shareReplay(1))
      : of([]);
  }
}
