import { Injectable } from '@angular/core';

export interface ChatButton {
  label: string;
  value: string | boolean; // what we send as user’s message or for logic
}

export interface ChatItem {
  id?: number;
  name: string;
  description: string;
}

export enum ChatMessageType {
  Text = 'text',
  Buttons = 'buttons',
  Items = 'items'
}

export enum ChatMessageSender {
  User = 'user',
  Assistant = 'assistant'
}

export interface ChatMessage {
  id?: string;
  sender: ChatMessageSender;
  type: ChatMessageType;
  text?: string;
  buttons?: ChatButton[]; // for 'buttons' type
  items?: ChatItem[]; // for 'items' type
  showButtons?: boolean; // track if buttons are still shown
  showItems?: boolean; // track if items are still shown
  timestamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private _isChatOpen = false;
  private _messages: ChatMessage[] = [
    {
      sender: ChatMessageSender.Assistant,
      text: "Welcome! I'm your virtual AI assistant. How can I help you today?",
      timestamp: new Date(),
      type: ChatMessageType.Text
    }
  ];

  get isChatOpen(): boolean {
    return this._isChatOpen;
  }

  set isChatOpen(open: boolean) {
    this._isChatOpen = open;
  }

  get messages(): ChatMessage[] {
    return this._messages;
  }

  updateMessage(
    messageId: string,
    sender: ChatMessageSender,
    text: string,
    type: ChatMessageType = ChatMessageType.Text
  ) {
    const messageIndex = this._messages.findIndex(({ id }) => id === messageId);
    if (messageIndex !== -1) {
      const message = {
        ...this._messages[messageIndex],
        text,
        type
      };

      this._messages[messageIndex] = message;
    } else {
      this.messages.push({
        id: messageId,
        sender,
        type,
        text,
        timestamp: new Date()
      });
    }
  }

  addMessage(
    sender: ChatMessageSender,
    text: string,
    type: ChatMessageType = ChatMessageType.Text,
    buttons?: ChatButton[],
    items?: ChatItem[],
    id: string = `${Date.now()}_${sender}`
  ) {
    this._messages.push({
      id,
      sender,
      type,
      text,
      buttons,
      items,
      showButtons: !!buttons, // set to true if buttons exist
      showItems: !!items, // set to true if items exist
      timestamp: new Date()
    });
  }
}
