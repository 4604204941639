import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  CardModule,
  IconModule,
  InputModule,
  LinkModule,
  SidebarModule,
  StaticPagesModule,
  UserAvatarModule
} from '@ct/components';
import { PipesModule } from '@ct/core';
import { MediaSharedModule } from '@ct/shared';
import { TranslateModule } from '@ngx-translate/core';

import { MyAccountChatComponent, MyAccountComponent } from './components';
import { MyAccountSidebarComponent } from './components/my-account-sidebar';
import { MyAccountSharedModule } from './modules';
import { MyAccountOverviewModule } from './modules/my-account-overview';
import { MyAccountRoutingModule } from './my-account-routing.module';

@NgModule({
  declarations: [MyAccountComponent, MyAccountSidebarComponent, MyAccountChatComponent],
  imports: [
    CommonModule,
    MyAccountRoutingModule,
    MyAccountOverviewModule,
    TranslateModule,
    CardModule,
    PipesModule,
    InputModule,
    ReactiveFormsModule,
    MyAccountSharedModule,
    LinkModule,
    SidebarModule,
    MediaSharedModule,
    StaticPagesModule,
    ButtonModule,
    IconModule,
    UserAvatarModule
  ]
})
export class MyAccountModule {}
