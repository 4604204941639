<div class="px-4 px-md-5 py-4 group-timeline">
  <ct-my-account-group-add-entry
    *ngIf="canPost"
    (addToTimeline)="onAddTimeline($event)"
  ></ct-my-account-group-add-entry>
  <div *ngFor="let timeline of groupTimelines; trackBy: trackByFn" class="mb-4 timeline-entity main-content">
    <ng-container *ngTemplateOutlet="elementHeader; context: { entity: timeline }"></ng-container>

    <p *ngIf="timeline.body" class="timeline-entity__message">{{ timeline.body }}</p>
    <ng-container *ngIf="timeline.type === timelineType.Journal && isMember">
      <ct-journal-entry
        *ngIf="timeline.journal; else noEntity"
        [journalEntry]="toJournalEntry(timeline.journal)"
        [canEdit]="false"
      ></ct-journal-entry>
    </ng-container>
    <ng-container *ngIf="timeline.type === timelineType.BlogPost && isMember">
      <ct-blog-post-card
        *ngIf="timeline.story; else noEntity"
        [post]="timeline.story"
        [showLink]="true"
        [showStatus]="true"
        imgHeight="220px"
        imgWidth="100%"
        [link]="getStoryLink(timeline)"
      ></ct-blog-post-card>
    </ng-container>
    <ng-container *ngIf="timeline.type === timelineType.Trip && isMember">
      <ct-trip-card
        *ngIf="timeline.trip; else noEntity"
        class="cursor--pointer"
        [trip]="timeline.trip"
        [link]="getTripLink(timeline)"
      ></ct-trip-card>
    </ng-container>
    <ng-container *ngIf="!isMember && !timeline.body">
      <div class="warn">You don't have access to this entity</div>
    </ng-container>
  </div>
</div>
<div
  *ngIf="showLoadButton && groupTimelines?.length"
  class="load-more"
  [ngClass]="{ disabled: loading }"
  (click)="onScroll()"
  disabled="loading"
>
  Load More
</div>

<ng-template
  #elementHeader
  let-entity="entity"
  let-author="entity?.author"
  let-type="entity?.type"
  let-createdOn="entity?.createdOn"
>
  <div class="header row">
    <div class="user col-12">
      <ct-user-avatar
        class="user__avatar"
        [src]="getCroppedThumbPublicUrl(author?.photo?.publicUrl)"
        width="32px"
        height="32px"
      ></ct-user-avatar>
      <p class="user__link">
        <span
          *ngIf="author; else deletedUser"
          class="user__name"
          [routerLink]="['/user-profile']"
          [queryParams]="{ userId: author.userId }"
          >{{ author.firstName }} {{ author.lastName }}</span
        >
        <ng-template #deletedUser>
          <span class="user__name">Deleted User</span>
        </ng-template>
        <ng-container *ngIf="type === timelineType.Trip">
          <span class="user__subtitle"> shared the trip on {{ createdOn | date : 'MMM d yyyy, HH:mm' }} </span>
        </ng-container>
        <ng-container *ngIf="type === timelineType.Journal">
          <span class="user__subtitle"> shared the memory on {{ createdOn | date : 'MMM d yyyy, HH:mm' }} </span>
        </ng-container>
        <ng-container *ngIf="type === timelineType.BlogPost">
          <span class="user__subtitle"> shared the story on {{ createdOn | date : 'MMM d yyyy, HH:mm' }} </span>
        </ng-container>
      </p>
      <ct-icon
        *ngIf="isAuthor(entity) || isAdmin"
        (click)="deleteEntity(entity)"
        icon="delete"
        class="delete-entity__icon"
      ></ct-icon>
    </div>
  </div>
</ng-template>

<ng-template #noEntity>
  <div class="no-entity">
    {{ 'Shared entity is not available anymore' | translate }}
  </div>
</ng-template>
