import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '@ct/components';
import { DestroyableFeature, Features, FormStateDispatcher } from '@ct/core';
import { trackById, Trip, TripSharingStatus } from '@ct/shared';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { MY_ACCOUNT_TRIPS_FEATURE_LIMIT } from '../../my-account/constants';
import { MyAccountTripApiService } from '../../my-account/modules/my-account-trips/services';

interface TripQueryParams {
  offset: number;
  filter: TripSharingStatus;
}

const DEFAULT_OFFSET = 0;
const DEFAULT_FILTER = TripSharingStatus.MyTrips;

@Component({
  selector: 'ct-select-trip-dialog',
  templateUrl: './select-trip-dialog.component.html',
  styleUrls: ['./select-trip-dialog.component.scss'],
  providers: [FormStateDispatcher],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([DestroyableFeature()])
export class SelectTripDialogComponent {
  static dialogConfig: MatDialogConfig = {
    minWidth: '700px'
  };
  public readonly destroyed$: Observable<void>;
  public readonly limit = MY_ACCOUNT_TRIPS_FEATURE_LIMIT;
  public loading = false;
  public showLoadButton = true;
  public trips: Trip[] = [];

  public queryParams: TripQueryParams = {
    offset: DEFAULT_OFFSET,
    filter: DEFAULT_FILTER
  };

  public readonly trackByFn = trackById;

  constructor(
    private dialogRef: MatDialogRef<SelectTripDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private myAccountTripApiService: MyAccountTripApiService,
    private spinnerService: SpinnerService
  ) {
    this.loading = true;
    this.loadTrips(this.queryParams).subscribe((trips) => {
      this.trips.push(...trips);
      if (trips.length === 0 || trips.length < this.limit) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  onSelect(event: MouseEvent, trip: Trip) {
    event.stopPropagation();
    event.preventDefault();

    this.dialogRef.close(trip);
  }

  onScroll() {
    if (this.loading || !this.showLoadButton) {
      return false;
    }
    this.loading = true;
    this.queryParams.offset = this.queryParams.offset + this.limit;
    this.loadTrips(this.queryParams).subscribe((trips) => {
      this.trips.push(...trips);
      if (trips.length === 0 || trips.length < this.limit) {
        this.showLoadButton = false;
      }
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  loadTrips({ offset, filter }: TripQueryParams) {
    this.spinnerService.show();
    this.loading = true;
    return this.myAccountTripApiService.getAllMyTrips({ limit: this.limit, offset }, filter).pipe(
      finalize(() => {
        this.loading = false;
        this.spinnerService.hide();
      })
    );
  }
}
