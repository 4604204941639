import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChannelComponent } from './channel';
import {
  ChannelResolver,
  ChannelsResolver,
  ChannelVideosResolver,
  MyAccountGroupTimelineDataResolver
} from './resolvers';
import { UserChannelComponent } from './user-channel';
import { UserChannelsListComponent } from './user-channels-list';

const routes: Routes = [
  {
    path: '',
    component: ChannelComponent,
    children: [
      {
        path: '',
        component: UserChannelsListComponent,
        resolve: {
          channels: ChannelsResolver
        }
      },
      {
        path: ':channelName',
        component: UserChannelComponent,
        resolve: {
          channel: ChannelVideosResolver,
          group:ChannelResolver,
          timeline: MyAccountGroupTimelineDataResolver,
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChannelRoutingModule {}
