<ct-dialog-content [titleKey]="'Change Group Admins'" [closeIcon]="true" headerIcon="add-circle">
  <ng-container *ngFor="let user of users; trackBy: trackByFn">
    <div class="row">
      <div class="user col-12">
        <!--          TODO move to common component?-->
        <ct-user-avatar
          class="user__avatar"
          [src]="getCroppedThumbPublicUrl(user?.photo?.publicUrl)"
          width="32px"
          height="32px"
        ></ct-user-avatar>
        <div>
          <p class="user__name">{{ user.firstName }} {{ user.lastName }}</p>
          <p class="user__name user__name--light">{{ user.username }}</p>
        </div>
        <ct-button *ngIf="isAdmin(user); else notAdmin" class="ml-auto" color="warn" (click)="toggleAdmin(user)">{{
          'Remove Admin' | translate
        }}</ct-button>
        <ng-template #notAdmin>
          <ct-button class="ml-auto" (click)="toggleAdmin(user)">{{ 'Make Admin' | translate }}</ct-button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ct-dialog-content>
