import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthQuery } from '@ct/auth';
import { DialogService, TabHeader } from '@ct/components';
import { Features, TitleConfig, TitleFeature, UserProfile } from '@ct/core';
import { CreateGroupDialogComponent } from '@ct/shared';
import { NotificationQuery } from '@ct/shared/services/notification-state';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

enum Groups {
  MemberOf = 'member-of',
  AdminOf = 'admin-of',
  Public = 'public',
  PendingInvitations = 'pending'
}

@Component({
  selector: 'ct-my-account-groups',
  templateUrl: './my-account-groups.component.html',
  styleUrls: ['./my-account-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature()])
export class MyAccountGroupsComponent implements OnDestroy {
  public readonly destroyed$: Subject<unknown> = new Subject();
  public titleConfig: TitleConfig = {
    titleKey: 'MY_ACCOUNT.GROUPS'
  };

  public tabs: TabHeader[] = [
    { name: Groups.MemberOf, labelKey: 'Member Of', selected: true },
    { name: Groups.AdminOf, labelKey: 'Admin Of' },
    { name: Groups.Public, labelKey: 'Public' },
    { name: Groups.PendingInvitations, labelKey: 'Pending Invitations', shouldShow: () => this.hasPending }
  ];

  public loggedInUser$ = this.authQuery.profile$ as Observable<UserProfile>;
  public readonly notifications$ = this.notificationQuery.selectNotifications$;

  private get hasPending(): boolean {
    const profile = this.authQuery.profile as UserProfile;
    return Boolean(profile) && Boolean(profile.pendingInvitationGroupIds?.length);
  }

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private authQuery: AuthQuery,
    private notificationQuery: NotificationQuery
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        })
      )
      .subscribe((route: ActivatedRoute) => {
        const path = route.snapshot.url[0]?.path;
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          selected: tab.name === path
        }));
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onFilterChanged(tab: TabHeader) {
    this.router.navigate(['my-account/groups', tab.name]);
  }

  addItems() {
    this.dialogService.open(CreateGroupDialogComponent);
  }
}
