import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CanonicalConfig, CanonicalFeature, Features, TitleConfig, TitleFeature } from '@ct/core';

import { ChatService } from '../../services';

@Component({
  selector: 'ct-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([TitleFeature(), CanonicalFeature()])
export class MyAccountComponent {
  public titleConfig: TitleConfig = {
    titleKey: 'MAIN.FEATURES.MY_ACCOUNT'
  };
  public canonicalConfig: CanonicalConfig = {
    canonicalUrl: '/my-account'
  };

  constructor(public chatService: ChatService) {}

  toggleChat() {
    this.chatService.isChatOpen = !this.chatService.isChatOpen;
  }
}
