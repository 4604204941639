<div class="px-4 px-md-5 py-4 groups-list">
  <ct-group-card
    *ngFor="let group of groups; trackBy: trackByFn"
    [group]="group"
    imgHeight="100px"
    imgWidth="100%"
    class="mb-4"
    [link]="getLink(group)"
  ></ct-group-card>
  <ng-container *ngIf="!groups?.length">
    <ng-container *ngTemplateOutlet="noGroups"></ng-container>
  </ng-container>
</div>
<div
  *ngIf="showLoadButton && groups?.length"
  class="load-more"
  [ngClass]="{ disabled: loading }"
  (click)="onScroll()"
  disabled="loading"
>
  Load More
</div>

<ng-template #noGroups>
  <ct-empty-call-to-action
    buttonIcon="add-circle"
    emptyMessageTextKey="Looks like you don't have any group yet. It's time to create the new one."
    buttonTextKey="Create Group"
    (buttonClick)="onAddGroup()"
  ></ct-empty-call-to-action>
</ng-template>
