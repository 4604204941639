<ct-textarea [rows]="3" [formControl]="messageControl" label="Share your experience here"></ct-textarea>
<div *ngIf="selectedEntity" class="selected-entity">
  <ng-container [ngSwitch]="selectedEntityType">
    <ng-container *ngSwitchCase="entityType.Trip">
      <ng-container *ngTemplateOutlet="trip"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="entityType.Journal">
      <ng-container *ngTemplateOutlet="journal"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="entityType.BlogPost">
      <ng-container *ngTemplateOutlet="story"></ng-container>
    </ng-container>
  </ng-container>
  <ct-icon
    class="selected-entity__remove-icon"
    size="fit"
    title="Remove Attachment"
    icon="close"
    (click)="onRemoveEntity()"
  ></ct-icon>
</div>
<div class="d-flex pb-4">
  <ct-button variant="flat" class="mr-2" (click)="openShareDialog()">
    <ct-icon title="share" icon="share" class="mr-2"></ct-icon>
    Add Entity
  </ct-button>
  <ct-button color="primary" variant="flat" (click)="onSubmit()">Send </ct-button>
</div>

<ng-template #trip> Trip: {{ selectedEntity?.title }} </ng-template>
<ng-template #story> Story: {{ selectedEntity?.title }} </ng-template>

<ng-template #journal> Memory: {{ selectedEntity?.body | striphtml }} </ng-template>
