import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortOrder } from '@ct/shared/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HeaderType } from '../enums';
import { GoogleCalendarEventItem, RequestRange, VideoChannelEntity, VideoUploadEntity } from '../interfaces';
import { BaseHttpService } from '../services';

const endpoint = environment.channelApiBaseUrl;

export type CreateVideoUpload = Pick<VideoUploadEntity, 'duration' | 'orientation' | 'width' | 'height' | 'fileName'>;

@Injectable({ providedIn: 'root' })
export class ChannelVideoUploadApiService extends BaseHttpService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, endpoint);
  }

  getAll({
    range,
    sortOrder = SortOrder.Desc,
    sortBy
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    sortBy?: keyof VideoChannelEntity;
  }): Observable<VideoChannelEntity[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    return this.get(``, params, { headers, withCredentials: true });
  }

  getAllVideos({
    range,
    sortOrder = SortOrder.Desc,
    sortBy,
    authorId
  }: {
    range?: RequestRange;
    sortOrder?: SortOrder;
    sortBy?: keyof VideoUploadEntity;
    authorId?: string;
  } = {}): Observable<VideoUploadEntity[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      params = params.append('sortOrder', sortOrder);
    }
    if (sortBy !== undefined && sortBy !== null) {
      params = params.append('sortBy', sortBy);
    }
    if (authorId !== undefined && authorId !== null) {
      params = params.append('authorId', authorId);
    }
    return this.get(`user`, params, {
      headers,
      withCredentials: true
    });
  }

  getVideosStatus(videoIds: string[]): Observable<VideoUploadEntity[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    params = params.append('videoIds', JSON.stringify(videoIds));
    return this.get(`user/videos-status`, params, {
      headers,
      withCredentials: true
    });
  }

  getGoogleCalendarEvents(calendarUrl: string): Observable<GoogleCalendarEventItem[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    params = params.append('calendarUrl', calendarUrl);
    return this.get(`google-calendar`, params, {
      headers,
      withCredentials: true
    }).pipe(map((data) => data.items));
  }

  create(channel: Omit<VideoChannelEntity, 'id'>): Observable<VideoChannelEntity> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.post<VideoChannelEntity>(``, this.prepareChannelEntity(channel), {}, {
      headers,
      withCredentials: true
    });
  }

  update(id: string, channel: Omit<VideoChannelEntity, 'id'>): Observable<VideoChannelEntity> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.patch<VideoChannelEntity>(`${id}`, this.prepareChannelEntity(channel), {}, {
      headers,
      withCredentials: true
    });
  }

  joinChannel(id: string): Observable<void> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.patch<null>(`${id}/join`, null, {}, {
      headers,
      withCredentials: true
    });
  }

  leaveChannel(id: string): Observable<void> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.delete<void>(`${id}/join`, {}, {
      headers,
      withCredentials: true
    });
  }

  getChannel(handle: string): Observable<VideoChannelEntity> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (handle !== undefined && handle !== null) {
      params = params.append('handle', handle);
    }
    return this.get(`info`, params, {
      headers,
      withCredentials: true
    });
  }

  getAllForChannel(handle: string, range?: RequestRange): Observable<any[]> {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    let params = new HttpParams();
    if (handle !== undefined && handle !== null) {
      params = params.append('handle', handle);
    }
    if (range?.limit !== undefined && range.limit !== null) {
      params = params.append('limit', range.limit as number);
    }
    if (range?.offset !== undefined && range.offset !== null) {
      params = params.append('offset', range.offset as number);
    }
    return this.get(`video`, params, {
      headers,
      withCredentials: true
    });
  }

  getFromChannelByFilename(handle: string, fileName: string): Observable<any> {
    let params = new HttpParams();
    if (handle !== undefined && handle !== null) {
      params = params.append('handle', handle);
    }
    return this.get(`video/${fileName}`, params);
  }

  getByFilename(fileName: string): Observable<any> {
    return this.get(`user/${fileName}`, { withCredentials: true });
  }

  getFilesByVideoId(id: string): Observable<any> {
    return this.get(`video/byId/${id}/files`, { withCredentials: true });
  }

  getById(id: string): Observable<VideoUploadEntity> {
    return this.get(`video/byId/${id}`);
  }

  removeVideoById(id: string): Observable<void> {
    return this.delete(`video/byId/${id}`);
  }

  uploadWithProgress(file: File, entity: CreateVideoUpload) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    const formData = new FormData();
    formData.append('file', file);
    for (const key in entity) {
      formData.append(key, String(entity[key as keyof CreateVideoUpload]));
    }
    // use http client directly as baseHttpService does not support reportProgress property
    return this.httpClient.post(this.URL + `/upload`, formData, { headers, reportProgress: true, observe: 'events' });
  }

  getUploadLink(entity: CreateVideoUpload) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    return this.httpClient
      .post<{ data: VideoUploadEntity & { uploadLink: string } }>(this.URL + `/upload-link`, entity, { headers })
      .pipe(map(({ data }) => data));
  }

  useUploadLink(file: File, url: string) {
    const headers = {
      [HeaderType.ContentType]: 'application/octet-stream'
    };
    return this.httpClient.put(url, file, { headers });
  }

  upload(file: File) {
    const headers = {
      [HeaderType.Accept]: 'application/json'
    };
    const formData = new FormData();
    formData.append('file', file);
    return this.post(`upload`, formData, {}, { headers });
  }

  private prepareChannelEntity(channel: VideoChannelEntity): VideoChannelEntity {
    const { avatarImage, bannerImage, id, handle, ...rest } = channel;
    const updatedHandle = handle === rest.adminIds?.[0] ? handle : handle ? toSnakeCase(handle) : rest.adminIds?.[0];
    return { ...rest, handle: updatedHandle, bannerImageId: bannerImage?.id, imageId: avatarImage?.id };
  }
}

function toSnakeCase(str = '') {
  const strArr = str.split(' ');
  const snakeArr = strArr.reduce((acc, val) => {
    return acc.concat(val.toLowerCase());
  }, [] as string[]);
  return snakeArr.join('_');
}
