<ct-layout [user]="loggedInUser$ | async" [notifications]="notifications$ | async">
  <ct-extended-header ctHeader icon="article" title="Groups"></ct-extended-header>

  <div ctContent>
    <ng-container *ngIf="isInvited">
      <ng-container *ngTemplateOutlet="invited"></ng-container>
    </ng-container>
    <ng-container *ngIf="requestedToJoin">
      <ng-container *ngTemplateOutlet="requested"></ng-container>
    </ng-container>
    <ng-container *ngIf="canJoin">
      <ng-container *ngTemplateOutlet="join"></ng-container>
    </ng-container>
    <div *ngIf="group" class="d-flex flex-row align-items-center justify-content-between mb-4">
      <div class="d-flex flex-row align-items-center">
        <ct-progressive-image
          *ngIf="group.image"
          [height]="'100px'"
          [width]="'100px'"
          [publicUrl]="group.image.publicUrl"
          [thumbnail]="group.image.xsPreview"
          class="group__image mr-4"
        >
        </ct-progressive-image>
        <h1 class="mb-0">{{ group.title }}</h1>
      </div>
      <ng-container *ngIf="isAdmin">
        <ng-container *ngTemplateOutlet="edit"></ng-container>
      </ng-container>
      <ng-container *ngIf="canLeave">
        <ng-container *ngTemplateOutlet="leave"></ng-container>
      </ng-container>
    </div>
    <ct-tab-header
      class="d-none d-md-flex"
      [tabs]="tabs"
      style="margin-bottom: -1px"
      (tabChange)="onFilterChanged($event)"
    >
    </ct-tab-header>
    <ct-tabs-mobile class="d-flex d-md-none" [tabs]="tabs" (tabChange)="onFilterChanged($event)"></ct-tabs-mobile>
    <router-outlet></router-outlet>
  </div>
</ct-layout>

<ng-template #invited>
  <mat-card class="mb-4 p-3">
    <div class="align-content-center align-items-baseline d-flex flex-row justify-content-between">
      <p>You've been invited to join this group</p>
      <div class="card-buttons d-flex">
        <ct-button color="primary" (click)="acceptInvitation()">Accept</ct-button>
        <ct-button color="warn" (click)="leaveGroup()">Dismiss</ct-button>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template #requested>
  <mat-card class="mb-4 p-3">
    <div class="align-content-center align-items-baseline d-flex flex-row justify-content-between">
      <p>You've sent join request to this group</p>
      <ct-button color="warn" (click)="leaveGroup()">Cancel</ct-button>
    </div>
  </mat-card>
</ng-template>

<ng-template #join>
  <mat-card class="mb-4 p-3">
    <div class="align-content-center align-items-baseline d-flex flex-row justify-content-between">
      <p>You are viewing public group. Would you like to join the discussion?</p>
      <div class="card-buttons d-flex">
        <ct-button color="primary" (click)="joinGroup()">Join</ct-button>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template #leave>
  <!-- TODO: think about another way to prohibit leaving initial Public group -->
  <ct-button *ngIf="group.title !== 'Public'" color="warn" (click)="leaveGroup()">Leave Group</ct-button>
</ng-template>

<ng-template #edit>
  <div class="d-flex">
    <ct-button class="ml-2" color="primary" [cdkMenuTriggerFor]="menu"><ct-icon icon="more"></ct-icon></ct-button>
    <ng-template #menu>
      <div class="d-flex flex-column" cdkMenu>
        <ct-button color="primary" [fullWidth]="true" (click)="leaveGroup()">Leave Group</ct-button>
        <ct-button color="primary" [fullWidth]="true" (click)="editGroup()">Edit Group</ct-button>
        <ct-button color="primary" [fullWidth]="true" (click)="manageAdmins()">Change Group Admins</ct-button>
      </div>
    </ng-template>
  </div>
</ng-template>
