<ct-dialog-content titleKey="Select Story To Share" [closeIcon]="true" headerIcon="add-circle">
  <div
    class="row mb-4 trips-list"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    <div
      *ngFor="let post of blogPosts; trackBy: trackByFn"
      class="col-12 mb-3 cursor--pointer"
      (click)="onSelect($event, post)"
    >
      <ct-blog-post-card
        class="no-events"
        [post]="post"
        [showLink]="false"
        [showStatus]="true"
        imgHeight="220px"
        imgWidth="100%"
      ></ct-blog-post-card>
    </div>
  </div>
  <div
    *ngIf="showLoadButton"
    class="load-more"
    [ngClass]="{ disabled: loading }"
    (click)="onScroll()"
    disabled="loading"
  >
    Load More
  </div>
</ct-dialog-content>
